// import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {logout} from './Api/Auth';
import Page404 from './Pages/Page';
import SplashScreen from './Pages/SplashScreen';
import IntroScreen from './Pages/IntroScreen';
import NewsList from './Pages/News/NewsList';
import Login from './Pages/Login';
import Register from './Pages/Register';
import RegisterVerification from './Pages/RegisterVerification';

import Home from './Pages/Home';
import About from './Pages/App/About';
import Support from './Pages/App/Support';
import FAQ from './Pages/App/FrequentQuestions';
import TouristSpot from './Pages/TouristSpot/SpotList';
import ProductInsurance from './Pages/Product/Insurance';

import Profile from './Pages/Profile/MyProfile';
import PersonalDetails from './Pages/Profile/PersonalDetails';
import ChangePassword from './Pages/Profile/ChangePassword';

import ClaimView from './Pages/Claim/ClaimView';
import ClaimHistory from './Pages/Claim/ClaimHistory';
import ClaimForm from './Pages/Claim/FormRegister';
import ClaimFormDetail from './Pages/Claim/FormRegisterDetail';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Page404/>}></Route>
        <Route path="/" exact element={<SplashScreen/>}></Route>
        <Route path="/intro" exact element={<IntroScreen/>}></Route>
        <Route path="/news" exact element={<NewsList/>}></Route>
        <Route path="/login" exact element={<Login/>}></Route>
        <Route path="/logout" onEnter={() => logout} ></Route>
        <Route path="/register" exact element={<Register/>}></Route>
        <Route path="/register-verification" exact element={<RegisterVerification/>}></Route>
        <Route path="/home" element={<Home/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/support" element={<Support/>}></Route>
        <Route path="/faq" element={<FAQ/>}></Route>
        <Route path="/profile" element={<Profile/>}></Route>
        <Route path="/personal-details" element={<PersonalDetails/>}></Route>
        <Route path="/change-password" element={<ChangePassword/>}></Route>
        <Route path="/claim-history" element={<ClaimHistory/>}></Route>
        <Route path="/claim-view" element={<ClaimView/>}></Route>
        <Route path="/claim-form" element={<ClaimForm/>}></Route>
        <Route path="/claim-form-detail" element={<ClaimFormDetail/>}></Route>
        <Route path="/tourist-spot" exact element={<TouristSpot/>}></Route>
        <Route path="/product-insurance" exact element={<ProductInsurance/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
