import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL
const token = localStorage.getItem('token')
const config = {
  headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" }
}

const configNoAuth = {
  headers: { 
              // 'Content-Type': 'application/x-www-form-urlencoded', 
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "false",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTION",
            }
}

export const bankList = async () => {
  const req = await axios.get(`${baseUrl}/master/bank`, config)
  // console.log(req.data)
  return req.data
}

export const benefitList = async () => {
  const req = await axios.get(`${baseUrl}/master/benefit`, config)
  return req.data
}

export const countryList = async () => {
  const req = await axios.get(`${baseUrl}/master/country`, config)
  return req.data
}

export const touristSpot = async () => {
  const req = await axios.get(`${baseUrl}/master/ts`, configNoAuth)
  return req.data
}

export const productInsurance = async () => {
  const req = await axios.get(`${baseUrl}/master/insurance-product`, configNoAuth)
  return req.data
}