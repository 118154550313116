import React, {useEffect} from 'react'
import { useNavigate } from "react-router-dom"

import Header from '../Components/Header'
import Main from '../Components/Main'
import Footer from '../Components/Footer'
import SidePanel from '../Components/SidePanel'
import SearchBar from '../Components/SearchBar'
import ModalShare from '../Components/ModalShare'

const Home = () => {
  const navigate = useNavigate();
  const checkUserToken = () => {
    const userToken = localStorage.getItem('token')
    if (!userToken || userToken === 'undefined') {
      return navigate('/login')
    }
  }

  const userFullname = localStorage.getItem('fullname')
  const userEmail = localStorage.getItem('email')
  useEffect(() => {
    checkUserToken()
  })
  
  return(
    <div>
      <div id="wrapper">
        <div id="content">
          <Header/>
          <Main name={userFullname ? userFullname : ''} email={userEmail ? userEmail : ''}/>
        </div>
        <Footer/>
        <SearchBar/>
        <SidePanel/>
        <ModalShare/>
      </div>
    </div>
  )
}
export default Home