import React from 'react'

function Header(){
  return (
    <header className="main_haeder header-sticky multi_item">
      <div className="em_side_right">
        {/* <Link className="rounded-circle d-flex align-items-center text-decoration-none" to="/home">
          <i className="tio-chevron_left size-24 color-text" />
          <span className="color-text size-14">Back</span>
        </Link> */}
        <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
          <i className="tio-chevron_left size-24 color-text" />
          <span className="color-text size-14">Home</span>
        </a>
      </div>
      <div className="title_page">
        <span className="page_name">
          Claim View
        </span>
      </div>
      {/* <div className="em_side_right">
        <button className="btn rounded-circle share-button bg-transparent" data-toggle="modal" data-target="#mdllButtons_share">
          <i className="ri-share-forward-box-line" />
        </button>
      </div> */}
    </header>
  )
}
export default Header