import React, {useState, useEffect, useRef} from 'react'
import { useNavigate} from "react-router-dom"
import {register} from '../Api/Auth'

function Register(){
  const navigate = useNavigate()
  const [fullname, setFullname] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [validation, setValidation] = useState("")
  
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const inputRef = useRef(null);

  const regisHandler = async (e) => {
    setValidation("")
    setLoaderDisplayBg0('flex')
    e.preventDefault()
    setTimeout(function() { 
      const formData = new FormData();
      formData.append('fullname', fullname);
      formData.append('phone_number', phoneNumber);
      formData.append('email', email);
      formData.append('current_password', password);
      formData.append('current_password_confirmation', confirmPassword);
      register(formData).then((res) => {
        if(res.response === 'SUCCESS'){
          // navigate('/register-verification')
          // console.log('regishadler')
          // console.log(res.message)
          // window.location.replace('/register-verification');
          navigate('/register-verification', { state: { 
            email : email, 
            message : res.message, 
            id_user : res.id_user
          } })
        }else{
          setValidation(res.message)
          inputRef.current.focus()
        }
        setLoaderDisplayBg0('none')
      })
    }, 1000)
  }

  useEffect(function() {
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
    inputRef.current.focus()
  },[])

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content">
        {/* Start main_haeder */}
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/login">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              {/* title here.. */}
            </span>
          </div>
          <div className="em_side_right">
            <a href="/login" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all">
              Sign In</a>
          </div>
        </header>
        {/* End.main_haeder */}
        {/* Start em__signTypeOne */}
        <section className="em__signTypeOne typeTwo np__account padding-t-70">
          <div className="em_titleSign">
            <h1>Let's Get Started!</h1>
            <p>Register the form to continue</p>
          </div>
          <div className="em__body">
            <form onSubmit={regisHandler} className="padding-t-40">
              <div className="margin-b-30">
                <div className="form-group input-lined lined__iconed">
                  <div className="input_group">
                    <input type="text" className="form-control" id="username" ref={inputRef} placeholder="Enter full name" value={fullname} onChange={(e) => setFullname(e.target.value)} required />
                    <div className="icon">
                      <svg id="Iconly_Curved_Profile" data-name="Iconly/Curved/Profile" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                        <g id="Profile" transform="translate(5 2.4)">
                          <path id="Stroke_1" data-name="Stroke 1" d="M6.845,7.3C3.153,7.3,0,6.726,0,4.425S3.133,0,6.845,0c3.692,0,6.845,2.1,6.845,4.4S10.556,7.3,6.845,7.3Z" transform="translate(0 11.962)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M4.387,8.774a4.372,4.372,0,1,0-.031,0Z" transform="translate(2.45 0)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <label htmlFor="username">Full name</label>
                </div>
              </div>
              <div className="margin-b-30">
                <div className="form-group input-lined lined__iconed">
                  <div className="input_group">
                    <input type="text" className="form-control" id="phone_number" name="phone_number" placeholder="0800 0000 0000" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                    <div className="icon">
                      <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                        <g id="Message" transform="translate(2.248 2.614)">
                          <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <label>Phone Number</label>
                </div>
              </div>
              <div className="margin-b-30">
                <div className="form-group input-lined lined__iconed">
                  <div className="input_group">
                    <input type="email" className="form-control" id="email" name='email' placeholder="example@mail.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <div className="icon">
                      <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                        <g id="Message" transform="translate(2.248 2.614)">
                          <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <label htmlFor="email">Email Address</label>
                </div>
              </div>
              <div className="margin-b-30">
                <div className="form-group input-lined lined__iconed">
                  <div className="input_group">
                    <input type="password" name="password" className="form-control" placeholder="enter your password" minLength="8" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <div className="icon">
                      <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                        <g id="Password" transform="translate(2.521 2.521)">
                          <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                    <button type="button" className="btn hide_show icon_password">
                      <i className="tio-hidden_outlined" />
                    </button>
                  </div>
                  <label htmlFor="pass">Password</label>
                </div>
              </div>
              <div className="form-group input-lined lined__iconed" id="show_hide_password">
                <div className="input_group">
                  <input type="password" name="confirm_password" className="form-control" placeholder="enter your password" minLength="8" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Password" transform="translate(2.521 2.521)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                  <button type="button" className="btn hide_show icon_password">
                    <i className="tio-hidden_outlined" />
                  </button>
                </div>
                <label htmlFor="pass">Confirm Password</label>
              </div>
              <div className="alert alert-light text-center" role="alert">
                {
                  validation !== "" ? (<div>{validation}</div>) : ''
                }
              </div>
              <div className="buttons__footer text-center">
                <button type="submit" className="btn bg-primary rounded-pill btn__default">
                  <span className="color-white">Sign Up</span>
                  <div className="icon rounded-circle">
                    <i className="tio-chevron_right" />
                  </div>
                </button>
              </div>
            </form>
          </div>
        </section>
        {/* End. em__signTypeOne */}
      </div>
    </div>
  )
}

export default Register;