import React, {Component} from 'react';

class Footer extends Component{
  render(){
    return (
      <div>
        {/* Start em_main_footer */}
        <footer className="em_main_footer ouline_footer with__text">
          <div className="em_body_navigation -active-links">
            <div className="item_link">
              <a href="page-products-fullwidth.html" className="btn btn_navLink ">
                <div className="icon_current ">
                  <svg id="Iconly_Curved_Bag" data-name="Iconly/Curved/Bag" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                    <g id="Bag" transform="translate(2.95 2.55)">
                      <path id="Stroke_1" data-name="Stroke 1" d="M8.659,4.32A4.33,4.33,0,0,0,0,4.3V4.32" transform="translate(4.755 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_3" data-name="Stroke 3" d="M.523.5H.477" transform="translate(11.5 8.324)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_5" data-name="Stroke 5" d="M.523.5H.477" transform="translate(5.669 8.324)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_7" data-name="Stroke 7" d="M9.084,14.934c-6.508,0-7.257-2.05-8.718-7.467C-1.1,2.033,1.841,0,9.084,0S19.268,2.033,17.8,7.467C16.341,12.884,15.592,14.934,9.084,14.934Z" transform="translate(0 4.006)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                    </g>
                  </svg>
                </div>
                {/* <div className="items_basket_circle">2</div> */}
                <div className="txt__tile">Promo</div>
              </a>
            </div>
            <div className="item_link">
              <a href="app-components.html" className="btn btn_navLink">
                <div className="icon_current">
                  <svg id="Iconly_Curved_More_Circle" data-name="Iconly/Curved/More Circle" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                    <g id="More_Circle" data-name="More Circle" transform="translate(2 2)">
                      <path id="Stroke_4" data-name="Stroke 4" d="M0,9.25C0,2.313,2.313,0,9.25,0S18.5,2.313,18.5,9.25,16.187,18.5,9.25,18.5,0,16.187,0,9.25Z" transform="translate(0.75 0.75)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_11" data-name="Stroke 11" d="M.5.5H.5" transform="translate(12.709 11.4)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                      <path id="Stroke_13" data-name="Stroke 13" d="M.5.5H.5" transform="translate(9.709 7.4)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                      <path id="Stroke_15" data-name="Stroke 15" d="M.5.5H.5" transform="translate(6.7 11.4)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                    </g>
                  </svg>
                </div>
                <div className="txt__tile">Market</div>
              </a>
            </div>
            <div className="item_link">
              <a href="/home" className="btn btn_navLink without_active">
                <button type="button" className="btn btnCircle_default rounded-10">
                  <svg id="Iconly_Curved_Home" data-name="Iconly/Curved/Home" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                    <g id="Home" transform="translate(2 1.667)">
                      <path id="Stroke_1" data-name="Stroke 1" d="M0,.5H4.846" transform="translate(5.566 11.28)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_2" data-name="Stroke 2" d="M0,9.761C0,5.068.512,5.4,3.266,2.842,4.471,1.872,6.346,0,7.965,0S11.5,1.862,12.712,2.842c2.754,2.554,3.265,2.227,3.265,6.919,0,6.906-1.633,6.906-7.988,6.906S0,16.667,0,9.761Z" transform="translate(0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                    </g>
                  </svg>
                </button>
              </a>
            </div>
            <div className="item_link">
              <a href="/faq" className="btn btn_navLink">
                <div className="icon_current">
                  <svg id="Iconly_Curved_Document" data-name="Iconly/Curved/Document" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                    <g id="Document" transform="translate(3.61 2.75)">
                      <path id="Stroke_1" data-name="Stroke 1" d="M7.22.5H0" transform="translate(4.766 12.446)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_2" data-name="Stroke 2" d="M7.22.5H0" transform="translate(4.766 8.686)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_3" data-name="Stroke 3" d="M2.755.5H0" transform="translate(4.766 4.927)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_4" data-name="Stroke 4" d="M0,9.25c0,6.937,2.1,9.25,8.391,9.25s8.391-2.313,8.391-9.25S14.685,0,8.391,0,0,2.313,0,9.25Z" transform="translate(0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                    </g>
                  </svg>
                </div>
                <div className="txt__tile">FAQ</div>
              </a>
            </div>
            <div className="item_link">
              <a href="/profile" className="btn btn_navLink">
                <div className="icon_current">
                  {/* <svg id="Iconly_Curved_Setting" data-name="Iconly/Curved/Setting" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                    <g id="Setting" transform="translate(3.5 2.5)">
                      <path id="Stroke_1" data-name="Stroke 1" d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" transform="translate(6 7)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      <path id="Stroke_3" data-name="Stroke 3" d="M16.668,4.75h0a2.464,2.464,0,0,0-3.379-.912,1.543,1.543,0,0,1-2.314-1.346A2.484,2.484,0,0,0,8.5,0h0A2.484,2.484,0,0,0,6.025,2.492,1.543,1.543,0,0,1,3.712,3.839a2.465,2.465,0,0,0-3.38.912,2.5,2.5,0,0,0,.906,3.4,1.56,1.56,0,0,1,0,2.692,2.5,2.5,0,0,0-.906,3.4,2.465,2.465,0,0,0,3.379.913h0a1.542,1.542,0,0,1,2.313,1.345h0A2.484,2.484,0,0,0,8.5,19h0a2.484,2.484,0,0,0,2.474-2.492h0a1.543,1.543,0,0,1,2.314-1.345,2.465,2.465,0,0,0,3.379-.913,2.5,2.5,0,0,0-.905-3.4h0a1.56,1.56,0,0,1,0-2.692A2.5,2.5,0,0,0,16.668,4.75Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                    </g>
                  </svg> */}
                  <svg id="Male_User_24" width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><rect width={24} height={24} stroke="none" fill="#000000" opacity={0} />
                    <g transform="matrix(0.77 0 0 0.77 12 12)">
                      <path style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeDashoffset: 0, strokeLinejoin: 'miter', strokeMiterlimit: 4, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: .4}} transform=" translate(-16, -16)" d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 16 8 C 13.25 8 11 10.25 11 13 C 11 14.515625 11.707031 15.863281 12.78125 16.78125 C 10.53125 17.949219 9 20.300781 9 23 L 11 23 C 11 20.226563 13.226563 18 16 18 C 18.773438 18 21 20.226563 21 23 L 23 23 C 23 20.300781 21.46875 17.949219 19.21875 16.78125 C 20.292969 15.863281 21 14.515625 21 13 C 21 10.25 18.75 8 16 8 Z M 16 10 C 17.667969 10 19 11.332031 19 13 C 19 14.667969 17.667969 16 16 16 C 14.332031 16 13 14.667969 13 13 C 13 11.332031 14.332031 10 16 10 Z" strokeLinecap="round" />
                    </g>
                  </svg>
                </div>
                <div className="txt__tile">Profile</div>
              </a>
            </div>
          </div>
        </footer>
          {/* End. em_main_footer */}
      </div>
    );
  }
}
export default Footer;