import React, {useEffect, useState} from 'react';
// import { useNavigate } from 'react-router-dom';

function IntroScreen(){
  // const navigate = useNavigate();
  const [loaderDisplay, setLoaderDisplay] = useState("flex")

  useEffect(() => {
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
  })

  return (
    <div id="wrapper" className="vh-100">
        <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
          <div className="spinner_flash"></div>
        </section>
        <div id="content">
          {/* Start main_haeder */}
          <header className="main_haeder header-sticky multi_item d-lfex justify-content-end">
            {/* <div className="em_side_right">
              <a href="index.html" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all">
                Skip
              </a>
            </div> */}
          </header>
          {/* End.main_haeder */}
          {/* Start npPage_introDefault */}
          <section className="npPage_introDefault padding-t-70">
            <div className="cover">
              <img src="assets/img/0sd6f8.jpg" alt="" />
            </div>
            {/* Swiper */}
            <div className="swiper-container swiper-intro-default swiper__text padding-t-40">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="content_text">
                    <h2 className="txt_gradient">From Checking to Buying</h2>
                    <p>
                      Velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum.
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="content_text">
                    <h2 className="txt_gradient">Connect Everywhere, Anytime.</h2>
                    <p>
                      Velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum.
                    </p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="content_text">
                    <h2 className="txt_gradient">All your servecis at the speed of light.</h2>
                    <p>
                      Velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum.
                    </p>
                  </div>
                </div>
              </div>
              {/* Add Pagination */}
              <div className="swiper-pagination" />
            </div>
            <div className="npButtons_networks env-pb margin-b-20">
              <a href="/login" className="btn rounded-pill border-snow invisible">
                {/* <div className="icon">
                  <img className="align-sub" width={19} height={19} src="assets/img/icon/google.svg" alt="" />
                </div> */}
                <span className="color-secondary">Sign In To Your Account</span>
              </a>
              <a href="/login" className="btn rounded-pill border-snow">
                {/* <div className="icon">
                  <img className="align-sub" width={19} height={19} src="assets/img/icon/google.svg" alt="" />
                </div> */}
                <span className="color-secondary">Sign In To Your Account</span>
              </a>
              <a href="/news" className="btn rounded-pill bg-primary">
                {/* <div className="icon c-white">
                  <svg className="w-19 h-19" id="Iconly_Curved_Call" data-name="Iconly/Curved/Call" xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19">
                    <g id="Call" transform="translate(1.979 1.979)">
                      <path id="Stroke_1" data-name="Stroke 1" d="M4.513,10.528C-.948,5.067-.171,2.566.4,1.76.478,1.63,2.3-1.1,4.255.5,9.1,4.5,2.965,3.932,7.037,8s3.509-2.067,7.5,2.782c1.6,1.955-1.128,3.777-1.257,3.851C12.476,15.213,9.975,15.99,4.513,10.528Z" transform="translate(0 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                    </g>
                  </svg>
                </div> */}
                <span className="color-white">Travel News</span>
              </a>
            </div>
          </section>
          {/* End. npPage_introDefault */}
        </div>
      </div>
  )
}

export default IntroScreen;