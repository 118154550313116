import React, {useEffect, useRef, useState} from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import {clientPlan, clientPlanDetail, registeredCompleted, submitClaim, submitClaimForm} from '../../Api/Claim';
import {bankList, benefitList, countryList} from '../../Api/Master';

function FormRegisterDetail(){
  const navigate = useNavigate()
  const location = useLocation()
  const btnSubmit= useRef('d-block')
  const btnSubmitLoading = useRef('d-none')
  const [btnSubmitView, setBtnSubmitView] = useState([])

  const userId = useRef(localStorage.getItem('user-id'))
  const client = useRef(location.state.client)
  const claimId = useRef(location.state.claimId)
  const claimNumber = useRef(location.state.claimNumber)
  const claimantName = useRef(location.state.claimantName)
  const claimantDob = useRef(location.state.claimantDob)
  const claimantGender = useRef(location.state.claimantGender)
  const claimantAddress = useRef(location.state.claimantAddress)
  const claimantTelephoneNo = useRef(location.state.claimantTelephoneNo)
  const claimantMobilePhone = useRef(location.state.claimantMobilePhone)
  const claimantEmail = useRef(location.state.claimantEmail)
  const claimantOfficePhone = useRef(location.state.claimantOfficePhone)
  const airPlane = useRef(location.state.airPlane)
  const flightNumber = useRef(location.state.flightNumber)
  const departureDate = useRef(location.state.departureDate)
  const bank = useRef('0')
  const accountNumber = useRef('')
  const onBehalfOf = useRef('')
  const plan = useRef('0')
  const benefit = useRef('0')
  const dateOfLoss = useRef()
  const placeOfLoss = useRef('')
  const inconvenience = useRef('0')
  const changeDateFlightTo = useRef('0')
  const dateArrival = useRef('')
  const dateReceiveBagage = useRef('')
  const hospital = useRef('0')
  const isAccident = useRef('0')
  const typeService = useRef('0')
  const admissionDate = useRef('')
  const dischargeDate = useRef('')
  const actualAmount = useRef('0')
  const currency = useRef('0')
  const chronology = useRef('')
  const complaints = useRef('')
  const dateReportToOtority = useRef('')
  const typeOfLoss = useRef('0')
  const remarksOfLoss = useRef('')
  const priceRange = useRef('0')
  const caseRelatedTo = useRef('0')

  const personIndexActive = useRef(0)
  const personNameActive = useRef('')
  const [viewPerson, setViewPersons] = useState([])
  const [listViewPersons, setListViewPersons] = useState([])

  const [listBank, setListBank] = useState([])
  const [listPlan, setListPlan] = useState([])
  const [listPlanDetail, setListPlanDetail] = useState([])
  const [listBenefit, setListBenefit] = useState([])
  const [listCountry, setListCountry] = useState([])

  const [viewInsuredInformation, setViewInsuredInformation] = useState([])
  const [viewFlightInformation, setViewFlightInformation] = useState([])
  const [viewPaymentInformation, setViewPaymentInformation] = useState([])

  const [viewClaimInformation, setViewClaimInformation] = useState([])
  const classInconvenience1 = useRef('d-none')
  const classInconvenience2 = useRef('d-none')
  const requiredInconvenience1 = useRef('')
  const requiredInconvenience2 = useRef('')

  var arr_detail_1 = ['3','2','4','5','6','7']
  var arr_detail_2 = ['8','9','10','11']
  var arr_detail_3 = ['1','13','14','15']
  var arr_detail_4 = ['16','17','18','19','20']
  var arr_detail_5 = ['21','22','23','24','25']

  const [persons, setPersons] = useState([{
    fullname: claimantName.current,
    dob: claimantDob.current,
    gender: claimantGender.current,
    address: claimantAddress.current,
    telephoneNumber: claimantTelephoneNo.current,
    mobilePhone: claimantMobilePhone.current,
    emailAddress: claimantEmail.current,
    officePhoneNumber: claimantOfficePhone.current,
    airPlane: airPlane.current,
    flightNumber: flightNumber.current,
    departureDate: departureDate.current,
    bank: bank.current,
    accountNumber: accountNumber.current,
    onBehalfOf: onBehalfOf.current,
    plan: plan.current,
    benefit: benefit.current,
    dateOfLoss: dateOfLoss.current,
    placeOfLoss: placeOfLoss.current,
    inconvenience: inconvenience.current,
    changeDateFlightTo: changeDateFlightTo.current,
    dateArrival: dateArrival.current,
    dateReceiveBagage: dateReceiveBagage.current,
    hospital: hospital.current,
    isAccident: isAccident.current,
    typeService: typeService.current,
    admissionDate: admissionDate.current,
    dischargeDate: dischargeDate.current,
    actualAmount: actualAmount.current,
    currency: currency.current,
    chronology: chronology.current,
    complaints: complaints.current,
    dateReportToOtority: dateReportToOtority.current,
    typeOfLoss: typeOfLoss.current,
    remarksOfLoss: remarksOfLoss.current,
    priceRange: priceRange.current,
    caseRelatedTo: caseRelatedTo.current
  }])

  const addPerson = async () => {
    setViewClaimInformation()
    var newPerson = {
      fullname: "P"+(persons.length+1),
      dob: '',
      gender: '0',
      address: "",
      telephoneNumber: "",
      mobilePhone: "",
      emailAddress: "",
      officePhoneNumber: "",
      airPlane: '',
      flightNumber: '',
      departureDate: '',
      bank: '0',
      accountNumber: '',
      onBehalfOf: '',
      benefit: '0',
      dateOfLoss: '',
      placeOfLoss: '',
      inconvenience: '',
      changeDateFlightTo: '',
      dateArrival: '',
      dateReceiveBagage: '',
      hospital: '0',
      isAccident: '0',
      typeService: '0',
      admissionDate: '',
      dischargeDate: '',
      actualAmount: '0',
      currency: '0',
      chronology: '',
      complaints: '',
      dateReportToOtority: '',
      typeOfLoss: '',
      remarksOfLoss: '',
      priceRange: '0',
      caseRelatedTo: '0'
    }

    setPersons([...persons, newPerson])
  }

  const getBankList = async () => {
    bankList().then((res) => {
      setListBank(res)
      paymentInformationView()
    })
  }

  const getClientPlan = () => {
    clientPlan(client.current).then((res) => {
      setListPlan(res)
    })
  }

  const getPlanDetail = async (plan) => {
    clientPlanDetail(plan).then((res) => {
      setListPlanDetail(res)
    })
  }

  const getBenefitList = async () => {
    benefitList().then((res) => {
      setListBenefit(res)
    })
  }

  const getCountryList = async () => {
    countryList().then((res) => {
      setListCountry(res)
    })
  }

  const viewBtnSubmit = () => {
    setBtnSubmitView(
      <div>
        <div className={btnSubmit.current}>
          <div className="buttons__footer text-center" style={{'width': '100%', 'bottom': '0'}}>
            <button type="submit" className="btn bg-primary rounded-pill btn__default text-center">
              <span className="color-white text-center">Submit</span>
              <div className="icon rounded-circle">
                <i className="tio-chevron_right" />
              </div>
            </button>
          </div>
        </div>
        <div className={btnSubmitLoading.current}>
          <div className="buttons__footer text-center" style={{'width': '100%', 'bottom': '0'}}>
            <button type="submit" className="btn bg-primary rounded-pill btn__default text-center" disabled>
              {/* <span className="spinner-border spinner-border-sm mr-2 color-white" role="status" aria-hidden="true" /> */}
              <span className="color-white text-center">Submit...</span>
              <div className="icon rounded-circle">
                <span className="spinner-border spinner-border-sm color-white" role="status" aria-hidden="true" />
              </div>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const personView = () => {
    setViewPersons(
      <button className="btn btn-outline-primary btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {personNameActive.current}
      </button>
    )
  }

  const personList = () => {
    setListViewPersons(
      <div className="dropdown-menu w-100 text-center" aria-labelledby="dropdownMenuButton">
        {
          persons.map((personList, personKey) => {
                                                    return (
                                                      <span key={personKey} className="dropdown-item" onClick={event => {changePerson(personKey)}}>{personList['fullname']}</span>
                                                    )
                                                  })
        }
      </div>
    )
  }

  const changePerson = (personIndex) => {
    personNameActive.current = persons[personIndex].fullname
    personIndexActive.current = personIndex
    personView()
    insuredInformationView()
    flightInformationView()
    paymentInformationView()
    if(persons[personIndex].benefit !== '0' || persons[personIndex].benefit !== '' || persons[personIndex].benefit !== null){
      if(arr_detail_1.includes(persons[personIndex].benefit ) === true){
        claimInformationView1()
      }else if(arr_detail_2.includes(persons[personIndex].benefit ) === true){
        claimInformationView2()
      }else if(arr_detail_3.includes(persons[personIndex].benefit ) === true){
        claimInformationView3()
      }else if(arr_detail_4.includes(persons[personIndex].benefit ) === true){
        claimInformationView4()
      }else if(arr_detail_5.includes(persons[personIndex].benefit ) === true){
        claimInformationView5()
      }else{
        setViewClaimInformation()
      }
    }else{
      setViewClaimInformation()
    }
  }

  const handleChange = (event) => {
    persons[personIndexActive.current][event.target.name] = (event.target.value)
    if(event.target.name === 'fullname'){
      personNameActive.current = event.target.value
      personView()
      personList()
    }
    insuredInformationView()
    flightInformationView()
    paymentInformationView()
    if(persons[personIndexActive.current].benefit !== '0' || persons[personIndexActive.current].benefit !== '' || persons[personIndexActive.current].benefit !== null){
      if(arr_detail_1.includes(persons[personIndexActive.current].benefit ) === true){
        claimInformationView1()
      }else if(arr_detail_2.includes(persons[personIndexActive.current].benefit ) === true){
        claimInformationView2()
      }else if(arr_detail_3.includes(persons[personIndexActive.current].benefit ) === true){
        claimInformationView3()
      }else if(arr_detail_4.includes(persons[personIndexActive.current].benefit ) === true){
        claimInformationView4()
      }else if(arr_detail_5.includes(persons[personIndexActive.current].benefit ) === true){
        claimInformationView5()
      }else{
        setViewClaimInformation()
      }
    }else{
      setViewClaimInformation()
    }
  }

  const planChange = (event) => {
    persons[personIndexActive.current][event.target.name] = (event.target.value)
    setViewClaimInformation()
    persons[personIndexActive.current]['benefit'] = ('0')
    getPlanDetail(event.target.value)
  }

  const benefitChange = (event) => {
    persons[personIndexActive.current][event.target.name] = (event.target.value)
    if(arr_detail_1.includes(event.target.value) === true){
      claimInformationView1()
    }else if(arr_detail_2.includes(event.target.value) === true){
      claimInformationView2()
    }else if(arr_detail_3.includes(event.target.value) === true){
      claimInformationView3()
    }else if(arr_detail_4.includes(event.target.value) === true){
      claimInformationView4()
    }else if(arr_detail_5.includes(event.target.value) === true){
      claimInformationView5()
    }
  }

  const inconvenienceChange = (event) => {
    persons[personIndexActive.current][event.target.name] = (event.target.value)
    if(event.target.value === 'Flight Delay'){
      classInconvenience1.current = 'd-block'
      classInconvenience2.current = 'd-none'
      requiredInconvenience1.current = 'required'
      requiredInconvenience2.current = ''
    }else{
      classInconvenience1.current = 'd-none'
      classInconvenience2.current = 'd-block'
      requiredInconvenience1.current = ''
      requiredInconvenience2.current = 'required'
    }

    claimInformationView3()
  }

  const insuredInformationView = () => {
    setViewInsuredInformation(
      <div className="padding-t-20">
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="fullname" className="form-control" value={persons[personIndexActive.current].fullname} onChange={handleChange} required />
            <label>Fullname <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="date" name="dob" className="form-control" value={persons[personIndexActive.current].dob} onChange={handleChange} required />
            <label>Date of Birth <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <select name="gender" className="form-control custom-select size-14" value={persons[personIndexActive.current].gender} onChange={handleChange} required>
              <option value='0'>Please Select</option>
              <option value="1">Male</option>
              <option value="2">Female</option>
            </select>
            <label>Gender <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Address <span className="text-danger">*</span></label>
            <textarea name="address" className="form-control" rows={2} value={persons[personIndexActive.current].address} onChange={handleChange} required />
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="telephoneNumber" className="form-control" placeholder="Telephone number" value={persons[personIndexActive.current].telephoneNumber} onChange={handleChange}/>
            <label>Telephone Number</label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="mobilePhone" className="form-control" placeholder="Phone number" value={persons[personIndexActive.current].mobilePhone} onChange={handleChange} required />
            <label>Mobile Phone <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="email" name="emailAddress" className="form-control" placeholder="Type your email address" value={persons[personIndexActive.current].emailAddress} onChange={handleChange} required />
            <label>Email Address <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="officePhoneNumber" className="form-control" placeholder="Office number" value={persons[personIndexActive.current].officePhoneNumber} onChange={handleChange} />
            <label>Office Phone Number</label>
          </div>
        </div>
      </div>
    )
  }

  const flightInformationView = () => {
    setViewFlightInformation(
      <div className="padding-t-20">
        <div className="margin-b-30">
          <div className="form-group input-lined lined__iconed">
            <div className="input_group">
              <select className="form-control custom-select size-14" name="airPlane" value={persons[personIndexActive.current].airPlane} onChange={handleChange} required>
                <option value='0' disabled>Please Select</option>
                <option>Batik Air</option>
                <option>Citilink</option>
                <option>Garuda Indonesia</option>
                <option>Indonesia AirAsia</option>
                <option>Lion Air</option>
                <option>Nam Air</option>
                <option>Pelita Air</option>
                <option>Sriwijaya Air</option>
                <option>TransNusa</option>
                <option>Wings Air</option>
              </select>
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M21.949 10.112C22.1634 10.9122 21.6886 11.7347 20.8884 11.9491L5.2218 16.1469C4.77856 16.2657 4.31138 16.0676 4.08866 15.6664L1.46582 10.9417L2.91471 10.5535L5.3825 12.998L10.4778 11.6328L5.96728 4.55914L7.89913 4.0415L14.8505 10.4611L20.1119 9.05131C20.9121 8.8369 21.7346 9.31177 21.949 10.112ZM4.00013 19.0002H20.0001V21.0002H4.00013V19.0002Z" fill="rgba(173,184,194,1)"></path></svg>
              </div>
            </div>
            <label>Airplane Name <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined lined__iconed">
            <div className="input_group">
              <input type="text" className="form-control" name="flightNumber" placeholder="Input your flight number" value={persons[personIndexActive.current].flightNumber} onChange={handleChange} required />
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM7 6H11V10H7V6ZM7 12H17V14H7V12ZM7 16H17V18H7V16ZM13 7H17V9H13V7Z" fill="rgba(173,184,194,1)"></path></svg>
              </div>
            </div>
            <label htmlFor="email">Flight Number <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="form-group input-lined lined__iconed">
          <div className="input_group">
            <input type="date" name="departureDate" className="form-control" placeholder="Input departure date" value={persons[personIndexActive.current].departureDate} onChange={handleChange} required />
            <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z" fill="rgba(173,184,194,1)"></path></svg>
            </div>
          </div>
          <label htmlFor="pass">Departure Date <span className="text-danger">*</span></label>
        </div>
      </div>
    )
  }

  const paymentInformationView = () => {
    setViewPaymentInformation(
      <div className="padding-t-20">
        <div className="margin-b-30">
          <div className="form-group input-lined lined__iconed">
            <div className="input_group">
              <select name="bank" className="form-control custom-select size-14" value={persons[personIndexActive.current].bank} onChange={handleChange} required>
                <option value='0'>Please Select</option>
                {
                  listBank.map((bank, Key) => {
                    return (
                      <option key={Key} value={Key}>{bank.bank_name}</option>
                    )
                  })
                }
              </select>
              <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM4 8.23607V9H20V8.23607L12 4.23607L4 8.23607ZM12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8Z" fill="rgba(173,184,194,1)"></path></svg>
              </div>
            </div>
            <label>Bank Name <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined lined__iconed">
            <div className="input_group">
              <input type="text" name="accountNumber" className="form-control" placeholder="Input your account number" value={persons[personIndexActive.current].accountNumber} onChange={handleChange} required />
              <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M3.00488 3H21.0049C21.5572 3 22.0049 3.44772 22.0049 4V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V4C2.00488 3.44772 2.4526 3 3.00488 3ZM20.0049 11H4.00488V19H20.0049V11ZM20.0049 9V5H4.00488V9H20.0049ZM14.0049 15H18.0049V17H14.0049V15Z" fill="rgba(173,184,194,1)"></path></svg>
              </div>
            </div>
            <label htmlFor="email">Account Number <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="form-group input-lined lined__iconed">
          <div className="input_group">
            <input type="text" name="onBehalfOf" className="form-control" placeholder="Account name" value={persons[personIndexActive.current].onBehalfOf} onChange={handleChange} required />
            <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M3 4.99509C3 3.89323 3.89262 3 4.99509 3H19.0049C20.1068 3 21 3.89262 21 4.99509V19.0049C21 20.1068 20.1074 21 19.0049 21H4.99509C3.89323 21 3 20.1074 3 19.0049V4.99509ZM5 5V19H19V5H5ZM7.97216 18.1808C7.35347 17.9129 6.76719 17.5843 6.22083 17.2024C7.46773 15.2753 9.63602 14 12.1022 14C14.5015 14 16.6189 15.2071 17.8801 17.0472C17.3438 17.4436 16.7664 17.7877 16.1555 18.0718C15.2472 16.8166 13.77 16 12.1022 16C10.3865 16 8.87271 16.8641 7.97216 18.1808ZM12 13C10.067 13 8.5 11.433 8.5 9.5C8.5 7.567 10.067 6 12 6C13.933 6 15.5 7.567 15.5 9.5C15.5 11.433 13.933 13 12 13ZM12 11C12.8284 11 13.5 10.3284 13.5 9.5C13.5 8.67157 12.8284 8 12 8C11.1716 8 10.5 8.67157 10.5 9.5C10.5 10.3284 11.1716 11 12 11Z" fill="rgba(173,184,194,1)"></path></svg>
            </div>
          </div>
          <label htmlFor="pass">On Behalf Of <span className="text-danger">*</span></label>
        </div>
      </div>
    )
  }

  const claimInformationView1 = () => {
    setViewClaimInformation(
      <div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="date" name="dateOfLoss" className="form-control" value={persons[personIndexActive.current].dateOfLoss} onChange={handleChange} required />
            <label>Date of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="placeOfLoss" className="form-control" value={persons[personIndexActive.current].placeOfLoss} onChange={handleChange} required />
            <label>Place of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="hospital" className="form-control" value={persons[personIndexActive.current].hospital} onChange={handleChange} required />
            {/* <select name="hospital" className="form-control custom-select size-14" value={persons[personIndexActive.current].hospital} onChange={handleChange} required>
              <option value='0'>Please Select</option>
            </select> */}
            <label>Hospital <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <select name="isAccident" className="form-control custom-select size-14" value={persons[personIndexActive.current].isAccident} onChange={handleChange} required>
              <option value='0'>Please Select</option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
            </select>
            <label>Is Accident <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <select name="typeService" className="form-control custom-select size-14" value={persons[personIndexActive.current].typeService} onChange={handleChange} required>
              <option value='0'>Please Select</option>
              <option value='Inpatient'>Inpatient</option>
              <option value='Outpatient'>Outpatient</option>
              <option value="Death">Death</option>
            </select>
            <label>Type Service <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="date" name="admissionDate" className="form-control" value={persons[personIndexActive.current].admissionDate} onChange={handleChange} required />
            <label>Admission Date <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="date" name="dischargeDate" className="form-control" value={persons[personIndexActive.current].dischargeDate} onChange={handleChange} required />
            <label>Discharge Date <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="number" min='0' name="actualAmount" className="form-control" value={persons[personIndexActive.current].actualAmount} onChange={handleChange} required />
            <label>Actual Amount <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <select name="currency" className="form-control custom-select size-14" value={persons[personIndexActive.current].currency} onChange={handleChange} required>
              <option value='0'>Please Select</option>
              {
                listCountry.map((country, Key) => {
                  if(country.iso_4217 !== "-" || country.iso_4217 !== '' || country.iso_4217 !== null){
                    return (
                      <option key={Key} value={country.iso_4217}>{country.iso_4217+' - '+country.name}</option>
                    )
                  }
                })
              }
            </select>
            <label>Currency <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Chronology <span className="text-danger">*</span></label>
            <textarea name="chronology" className="form-control" rows={2} value={persons[personIndexActive.current].chronology} onChange={handleChange} required />
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Complaints <span className="text-danger">*</span></label>
            <textarea name="complaints" className="form-control" rows={2} value={persons[personIndexActive.current].complaints} onChange={handleChange} required />
          </div>
        </div>
      </div>
    )
  }

  const claimInformationView2 = () => {
    setViewClaimInformation(
      <div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="date" name="dateOfLoss" className="form-control" value={persons[personIndexActive.current].dateOfLoss} onChange={handleChange} required />
            <label>Date of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="placeOfLoss" className="form-control" value={persons[personIndexActive.current].placeOfLoss} onChange={handleChange} required />
            <label>Place of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="date" name="dateReportToOtority" className="form-control" value={persons[personIndexActive.current].dateReportToOtority} onChange={handleChange} required />
            <label>Date Report to Otority <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <select name="typeOfLoss" className="form-control custom-select size-14" value={persons[personIndexActive.current].typeOfLoss} onChange={handleChange} required>
              <option value="0">Please Select</option>
              <option>Benda/Barang habis pakai</option>
              <option>Pakaian</option>
              <option>Hewan</option>
              <option>Alat Elektronik</option>
              <option>Uang Cash</option>
              <option>Makanan</option>
              <option>Lainnya</option>
            </select>
            <label>Type of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Remarks Of Loss  <span className="text-danger">*</span></label>
            <textarea name="remarksOfLoss" className="form-control" rows={2} value={persons[personIndexActive.current].remarksOfLoss} onChange={handleChange} required />
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="number" min='0' name="priceRange" className="form-control" value={persons[personIndexActive.current].priceRange} onChange={handleChange} required />
            <label>Price Range <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Chronology <span className="text-danger">*</span></label>
            <textarea name="chronology" className="form-control" rows={2} value={persons[personIndexActive.current].chronology} onChange={handleChange} required />
          </div>
        </div>
      </div>
    )
  }

  const claimInformationView3 = () => {
    setViewClaimInformation(
      <div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="datetime-local" name="dateOfLoss" className="form-control" value={persons[personIndexActive.current].dateOfLoss} onChange={handleChange} required />
            <label>Date of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="placeOfLoss" className="form-control" value={persons[personIndexActive.current].placeOfLoss} onChange={handleChange} required />
            <label>Place of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <select name="inconvenience" className="form-control custom-select size-14" value={persons[personIndexActive.current].inconvenience} onChange={inconvenienceChange} required>
              <option value='0'>Please Select</option>
              <option value='Flight Delay'>Flight Delay</option>
              <option value='Bagage Delay'>Bagage Delay</option>
            </select>
            <label>Inconvenience <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className={classInconvenience1.current}>
          <div className="margin-b-30">
            <div className="form-group input-lined">
              <input type="datetime-local" name="changeDateFlightTo" className="form-control" value={persons[personIndexActive.current].changeDateFlightTo} onChange={handleChange} />
              <label>Change Date Flight To <span className="text-danger">*</span></label>
            </div>
          </div>
        </div>

        <div className={classInconvenience2.current}>
          <div className="margin-b-30">
            <div className="form-group input-lined">
              <input type="datetime-local" name="dateArrival" className="form-control" value={persons[personIndexActive.current].dateArrival} onChange={handleChange} />
              <label>Date Arrival <span className="text-danger">*</span></label>
            </div>
          </div>
          <div className="margin-b-30">
            <div className="form-group input-lined">
              <input type="datetime-local" name="dateReceiveBagage" className="form-control" value={persons[personIndexActive.current].dateReceiveBagage} onChange={handleChange} />
              <label>Date Received Bagage <span className="text-danger">*</span></label>
            </div>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Chronology <span className="text-danger">*</span></label>
            <textarea name="chronology" className="form-control" rows={2} value={persons[personIndexActive.current].chronology} onChange={handleChange} required />
          </div>
        </div>
      </div>
    )
  }

  const claimInformationView4 = () => {
    setViewClaimInformation(
      <div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="datetime-local" name="dateOfLoss" className="form-control" value={persons[personIndexActive.current].dateOfLoss} onChange={handleChange} required />
            <label>Date of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="placeOfLoss" className="form-control" value={persons[personIndexActive.current].placeOfLoss} onChange={handleChange} required />
            <label>Place of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="date" name="dateReportTootority" className="form-control" value={persons[personIndexActive.current].dateReportToOtority} onChange={handleChange} required />
            <label>Date Report to Otority <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <select name="typeOfLoss" className="form-control custom-select size-14" value={persons[personIndexActive.current].typeOfLoss} onChange={handleChange} required>
              <option value='0'>Please Select</option>
              <option>Ticket Pesawat</option>
              <option>Hotel</option>
              <option>Atraksi/Tiket Masuk Wahana</option>
              <option>Transportasi Terjadwal Lainnya</option>
              <option>Lainnya</option>
            </select>
            <label>Type of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Remarks Of Loss <span className="text-danger">*</span></label>
            <textarea name="remarksOfLoss" className="form-control" rows={2} value={persons[personIndexActive.current].remarksOfLoss} onChange={handleChange} required />
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="number" min='0' name="priceRange" className="form-control" value={persons[personIndexActive.current].priceRange} onChange={handleChange} required />
            <label>Price Range <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <select name="caseRelatedTo" className="form-control custom-select size-14" value={persons[personIndexActive.current].caseRelatedTo} onChange={handleChange} required>
              <option value='0'>Please Select</option>
              <option>Rawat inap peserta atau keluarga dekat</option>
              <option>Meninggalnya peserta atau keluarga dekat</option>
              <option>Bencana alam</option>
              <option>Huru-hara atau adanya kerusuhan</option>
              <option>Lainnya</option>
            </select>
            <label>What's Case Related To <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Chronology <span className="text-danger">*</span></label>
            <textarea name="chronology" className="form-control" rows={2} value={persons[personIndexActive.current].chronology} onChange={handleChange} required />
          </div>
        </div>
      </div>
    )
  }

  const claimInformationView5 = () => {
    setViewClaimInformation(
      <div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="datetime-local" name="dateOfLoss" className="form-control" value={persons[personIndexActive.current].dateOfLoss} onChange={handleChange} required />
            <label>Date of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="text" name="placeOfLoss" className="form-control" value={persons[personIndexActive.current].placeOfLoss} onChange={handleChange} required />
            <label>Place of Loss <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group input-lined">
            <input type="date" name="dateReportTootority" className="form-control" value={persons[personIndexActive.current].dateReportTootority} onChange={handleChange} required />
            <label>Date Report to Otority <span className="text-danger">*</span></label>
          </div>
        </div>
        <div className="margin-b-30">
          <div className="form-group">
            <label>Chronology <span className="text-danger">*</span></label>
            <textarea name="chronology" className="form-control" rows={2} value={persons[personIndexActive.current].chronology} onChange={handleChange} required />
          </div>
        </div>
      </div>
    )
  }

  const formHandler = async (e) => {
    e.preventDefault()
    btnSubmit.current = 'd-none'
    btnSubmitLoading.current = 'd-block'
    viewBtnSubmit()
    const formData = new FormData()
    let formCompleted = 0
    setTimeout(function() { 
      persons.map((person, Key) => {
        const formData = new FormData();
        // console.log(persons[Key])
        formData.append('claim', claimId.current)
        formData.append('insured_name', persons[Key].fullname)
        formData.append('insured_dob', persons[Key].dob)
        formData.append('insured_gender', persons[Key].gender)
        formData.append('insured_address', persons[Key].address)
        formData.append('insured_telephone_no', persons[Key].telephoneNumber)
        formData.append('insured_mobile_phone', persons[Key].mobilePhone)
        formData.append('insured_email', persons[Key].emailAddress)
        formData.append('insured_office_phone', persons[Key].officePhoneNumber)
        formData.append('airlane_name', persons[Key].airPlane)
        formData.append('booking_number', persons[Key].flightNumber)
        formData.append('date_flight', persons[Key].departureDate)
        formData.append('bank', persons[Key].bank)
        formData.append('account_number', persons[Key].accountNumber)
        formData.append('on_behalf_of', persons[Key].onBehalfOf)
        formData.append('plan', persons[Key].plan)
        formData.append('benefit_claim', persons[Key].benefit)
        formData.append('date_accident', persons[Key].dateOfLoss)
        formData.append('location_accident', persons[Key].placeOfLoss)
        formData.append('hospital_name', persons[Key].hospital)
        formData.append('is_accident', persons[Key].isAccident)
        formData.append('type_service', persons[Key].typeService)
        formData.append('admission_date', persons[Key].admissionDate)
        formData.append('discharge_date', persons[Key].dischargeDate)
        formData.append('actual_amount', persons[Key].actualAmount)
        formData.append('currency', persons[Key].currency)
        formData.append('chronology', persons[Key].chronology)
        formData.append('complaints', persons[Key].complaints)
        formData.append('date_report_to_otority', persons[Key].dateReportToOtority)
        formData.append('type_of_loss', persons[Key].typeOfLoss)
        formData.append('remarks_of_loss', persons[Key].remarksOfLoss)
        formData.append('price_range', persons[Key].priceRange)
        formData.append('inconvenience', persons[Key].inconvenience)
        formData.append('change_date_flight_to', persons[Key].changeDateFlightTo)
        formData.append('date_arrival', persons[Key].dateArrival)
        formData.append('date_received_bagage', persons[Key].dateReceiveBagage)
        formData.append('case_related_to', persons[Key].caseRelatedTo)
        formData.append('user_id', userId.current)

        submitClaimForm(formData).then((res) => {
          formCompleted++
          if(formCompleted === persons.length){
            registeredCompleted(claimId.current).then((res) => {
              btnSubmit.current = 'd-block'
              btnSubmitLoading.current = 'd-none'
              viewBtnSubmit()
              navigate('/claim-view', { state: res.data[0].uuid })
            })
          }
        })
      })
    }, 1000)
  }

  useEffect(() => {
    persons.map((personList, personKey) => {
                                              personNameActive.current = personList['fullname']
                                              personIndexActive.current = personKey
                                            }) 
    personView()
    personList()
    insuredInformationView()
    flightInformationView()
    paymentInformationView()
  },[persons])

  useEffect(() => {
    paymentInformationView()
  },[listBank])

  useEffect(() => {
    let indexPerson = 0
    getBankList()
    getClientPlan()
    getCountryList()
    viewBtnSubmit()
    persons.map((personList, personKey) => {
                                              if(indexPerson < 1){
                                                indexPerson++
                                                personNameActive.current = personList['fullname']
                                                personIndexActive.current = personKey
                                              }
                                            })
    setListViewPersons(
      <div className="dropdown-menu w-100 text-center" aria-labelledby="dropdownMenuButton">
        {
          persons.map((personList, personKey) => {
                                                    return (
                                                      <span key={personKey} className="dropdown-item" onClick={event => {changePerson(personKey)}}>{personList['fullname']}</span>
                                                    )
                                                  }) 
        }
      </div>
    )

    flightInformationView()
  },[])

  return (
    <div>
      <div id="wrapper">
        <div id="content" className="">
          <div>
            {/* Start main_haeder */}
            <header className="main_haeder header-sticky multi_item">
              <div className="em_side_right">
                <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
                  <span className="color-text size-14">Cancel</span>
                </a>
              </div>
              <div className="title_page">
                <span className="page_name">
                  Claim Form Detail
                </span>
              </div>
            </header>
            <section className="em__signTypeOne typeTwo np__account padding-t-70">
              <form onSubmit={formHandler}>
                <div className="em_titleSign">
                  <h1>Detail Information</h1>
                  <p>Submit your detail information claim & click add person to adding your group</p>
                </div>
                <div className="em__body pb-0">
                  <section className="bg-white">
                    <div className="">
                      <div className="mb-2">
                        <label className="text-secondary">Registered Number</label>
                        <label className="float-right">{claimNumber.current}</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-7">
                        {viewPerson}
                        {listViewPersons}
                      </div>
                      <div className="col-5">
                        <button type="button" className='btn btn-primary btn-block' onClick={addPerson}>Add Person</button>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="padding-20 bg-snow my-3">
                  <h2 className="size-20 weight-600 color-secondary mb-1">Insured Information</h2>
                  <p className="size-12 color-text m-0">Submit your data as insured claim</p>
                </div>
                <div className="em__body pb-0">
                  {viewInsuredInformation}
                </div>
                <div className="padding-20 bg-snow my-3">
                  <h2 className="size-20 weight-600 color-secondary mb-1">Flight Information</h2>
                </div>
                <div className="em__body pb-0">
                  {viewFlightInformation}
                </div>
                <div className="padding-20 bg-snow my-3">
                  <h2 className="size-20 weight-600 color-secondary mb-1">Claim Information</h2>
                </div>
                <div className="em__body pb-0">
                  <div className="padding-t-20">
                    <div className="margin-b-30">
                      <div className="form-group input-lined lined__iconed">
                        <div className="input_group">
                          <select name="plan" className="form-control custom-select size-14" value={persons[personIndexActive.current].plan} onChange={planChange} required>
                            <option value='0'>Please Select</option>
                            {
                              listPlan.map((plan, Key) => {
                                return (
                                  <option key={Key} value={plan.id_plan}>{plan.plan_name}</option>
                                )
                              })
                            }
                          </select>
                          <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM4 8.23607V9H20V8.23607L12 4.23607L4 8.23607ZM12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8Z" fill="rgba(173,184,194,1)"></path></svg>
                          </div>
                        </div>
                        <label>Plan Name <span className="text-danger">*</span></label>
                      </div>
                      <div className="form-group input-lined lined__iconed">
                        <div className="input_group">
                          <select name="benefit" className="form-control custom-select size-14" value={persons[personIndexActive.current].benefit} onChange={benefitChange} required>
                            <option value='0'>Please Select</option>
                            {
                              listPlanDetail.map((benefit, Key) => {
                                return (
                                  <option key={Key} value={benefit.id_benefit}>{benefit.benefit_name}</option>
                                )
                              })
                            }
                          </select>
                          <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM4 8.23607V9H20V8.23607L12 4.23607L4 8.23607ZM12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8Z" fill="rgba(173,184,194,1)"></path></svg>
                          </div>
                        </div>
                        <label>Benefit Claim <span className="text-danger">*</span></label>
                      </div>
                      {/* <div className="form-group input-lined lined__iconed">
                        <div className="input_group">
                          <select name="benefit" className="form-control custom-select size-14" value={persons[personIndexActive.current].benefit} onChange={benefitChange} required>
                            <option value='0'>Please Select</option>
                            {
                              listBenefit.map((benefit, Key) => {
                                return (
                                  <option key={Key} value={benefit.id_benefit}>{benefit.benefit_name}</option>
                                )
                              })
                            }
                          </select>
                          <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM4 8.23607V9H20V8.23607L12 4.23607L4 8.23607ZM12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8Z" fill="rgba(173,184,194,1)"></path></svg>
                          </div>
                        </div>
                        <label>Benefit Claim <span className="text-danger">*</span></label>
                      </div> */}
                    </div>
                    {viewClaimInformation}
                  </div>
                </div>
                <div className="padding-20 bg-snow my-3">
                  <h2 className="size-20 weight-600 color-secondary mb-1">Payment Information</h2>
                </div>
                <div className="em__body pb-0">
                  {viewPaymentInformation}
                </div>
                {btnSubmitView}
              </form>
            </section>
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    </div>
  );
}
export default FormRegisterDetail