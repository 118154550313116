import React, {useEffect, useRef, useState} from 'react';
import Footer from '../../Components/Footer';
import { touristSpot } from '../../Api/Master';

function SpotList(){
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const isLogin = useRef(0)
  const [touristSpotRes, setTouristSpotRes] = useState(null)

  const getTouristSpot = async () => {
    touristSpot().then((res) => {
      setTouristSpotRes(res.data)
    })
  }

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token')
    if (!userToken || userToken === 'undefined') {
      isLogin.current = 0
    }else{
      isLogin.current = 1
    }
  }

  useEffect(() => {
    checkUserToken()
    getTouristSpot()
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
  },[])

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content" className="">
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Home</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              Tourist Spot
            </span>
          </div>
        </header>

        <section className="emCoureses__grid course__list">
          <div className="em_bodyCarousel padding-t-70">
            {
              touristSpotRes == null ? (
                <div className="text-center">
                  <span className="text-muted">Loading...</span>
                </div>
              ) : 
              touristSpotRes.map((touristSpot, Key) => {
                const desc = String(touristSpotRes[Key].description)
                return (
                  <div className="em_itemCourse_grid list">
                    <a href="page-chapters-learning.html" className="card">
                      <div className="row no-gutters">
                        <div className="col-4">
                          <div className="cover_card">
                            <img src={'https://tripinsglobal.com/app/file/master-data/tourist_spot/'+touristSpotRes[Key].image_cover} className="card-img-top" alt="img" />
                          </div>
                        </div>
                        <div className="col-8 my-auto">
                          <div className="card-body">
                            {/* <div className="head_card">
                              <span>- 5 h 30 min</span>
                              <span>45 lectures</span>
                            </div> */}
                            <h5 className="card-title">
                              {touristSpotRes[Key].title}
                            </h5>
                            <p className="card-text text-wrap">
                              {desc.substring(0, 100)+'...'}
                            </p>
                            <div className="card_footer">
                              <div className="card_user mt-1">
                                <span>{touristSpotRes[Key].province_name}</span>
                              </div>
                              <div className="size-14 weight-600 color-secondary">
                                <a href="#" className="size-12 weight-600 color-secondary">
                                  <span className='color-text'>Read more</span>
                                  <i className="tio-chevron_right" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </section>
      </div>
      {
        isLogin.current === 1 ? (<Footer/>) : ''
      }
    </div>
  )
}

export default SpotList;