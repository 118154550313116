import React, {Component} from "react";

class Page404 extends Component{
  render(){
    return(
      <div id="wrapper">
        <div id="content">
          {/* Start main_haeder */}
          <header className="main_haeder header-sticky bg-transparent border-none multi_item">
            <div className="em_side_right">
              <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
                <i className="tio-chevron_left size-24 color-text" />
                <span className="color-text size-14">home</span>
              </a>
            </div>
          </header>
          {/* End.main_haeder */}
          {/* Start */}
          <section className="emPage__404 basicErrors padding-t-80">
            <div className="illustration__img">
              <img src="assets/img/errors/404.svg" alt="" />
            </div>
            <div className="detaile__error text-center">
              <h1 className="color-secondary">Page Not Found</h1>
              <p className="color-text">The page you are looking for doesn't seem to exist…</p>
              <a href="/home" className="btn btn__gohome bg-primary color-white">Go Home</a>
            </div>
          </section>
          {/* End. */}
        </div>
      </div>
    );
  }
}
export default Page404;