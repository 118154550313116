import React, {useState, useEffect, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import {verification} from '../Api/Auth';

function RegisterVerification(){
  const navigate = useNavigate()
  const location = useLocation()
  const email = useRef(location.state.email)
  const message = useRef(location.state.message)
  const id_user = useRef(location.state.id_user)
  const [validation, setValidation] = useState("")
  const [secondExpired, setSecondExpired] = useState(60)
  const code = useRef("0")
  const [code1, setCode1] = useState("")
  const [code2, setCode2] = useState("")
  const [code3, setCode3] = useState("")
  const [code4, setCode4] = useState("")
  
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const [resendDisplay, setResendDisplay] = useState("none")
  const [timerDisplay, setTimerDisplay] = useState("inline")
  const [formVerifyDisplay, setFormVerifyDisplay] = useState("block")
  const inputRef = useRef(null)

  const verificationHandler = async (e) => {
    setValidation("")
    setLoaderDisplayBg0('flex')
    e.preventDefault()
    code.current = code1+code2+code3+code4
    setTimeout(function() { 
      const formData = new FormData();
      formData.append('id_user', id_user.current);
      formData.append('code', code.current);
      verification(formData).then((res) => {
        if(res.response === 'SUCCESS'){
          setFormVerifyDisplay('none')
          setValidation(res.message+'. You will be redirected to the Login Page in a few seconds')
          setTimeout(function() { 
            window.location.replace('/login');
          }, 3000)
        }else{
          setValidation(res.message)
          inputRef.current.focus()
        }
        setLoaderDisplayBg0('none')
      })
    }, 1000)
  }

  const resendCode = async (e) => {
    // console.log('Request has been sent')
  }

  useEffect(function() {
    if(secondExpired === 0){
      setTimerDisplay('none')
      setResendDisplay('inline')
    }

    secondExpired > 0 && setTimeout(() => setSecondExpired(secondExpired - 1), 1000)
  }, [secondExpired])

  useEffect(function() {
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
    inputRef.current.focus()
  },[])

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content">
        {/* Start main_haeder */}
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/register">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              {/* title here.. */}
            </span>
          </div>
          <div className="em_side_right">
            {/* <a href="#" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all">
              Need some help?
            </a> */}
          </div>
        </header>
        {/* End.main_haeder */}
        {/* Start em__signTypeOne */}
        <section className="em__signTypeOne typeTwo np__account padding-t-70">
          <div className="em_titleSign">
            <h1>Email Verification</h1>
            <p>{message.current} <span className="size-13 color-secondary">{email.current}</span></p>
          </div>
          <div className="em__body">
            <form onSubmit={verificationHandler} className="padding-t-40">
              <div className="verification__Code withBorder__items" style={{display: formVerifyDisplay}}>
                <div className="input_number">
                  <input ref={inputRef} type="tel" className="form-control input_number" maxLength={1} size={1} min={0} max={9} pattern="[0-9]{1}" value={code1} onChange={(e) => setCode1(e.target.value)} placeholder="-" />
                  <input type="tel" className="form-control input_number" maxLength={1} size={1} min={0} max={9} pattern="[0-9]{1}" value={code2} onChange={(e) => setCode2(e.target.value)} placeholder="-" />
                  <input type="tel" className="form-control input_number" maxLength={1} size={1} min={0} max={9} pattern="[0-9]{1}" value={code3} onChange={(e) => setCode3(e.target.value)} placeholder="-" />
                  <input type="tel" className="form-control input_number" maxLength={1} size={1} min={0} max={9} pattern="[0-9]{1}" value={code4} onChange={(e) => setCode4(e.target.value)} placeholder="-" />
                </div>
              </div>
              <div className="alert alert-light text-center" role="alert">
                {
                  validation !== "" ? (<div>{validation}</div>) : ''
                }
              </div>
              <div className="buttons__footer text-center" style={{display: formVerifyDisplay}}>
                <div className="padding-b-10">
                  <p className="color-text size-14 text-center">
                    Not receive any code? 
                    <span style={{display: timerDisplay}} className="color-snow"> Resend in <b>{secondExpired}</b>s</span>
                    <a href="javascript:void(0)" onClick={event => {resendCode()}} style={{display: resendDisplay}}>
                      <span className="size-14"> Resend</span>
                    </a>
                  </p>
                </div>
                <button type="submit" className="btn bg-primary rounded-pill btn__default">
                  <span className="color-white">Verify Code</span>
                  <div className="icon rounded-circle">
                    <i className="tio-chevron_right" />
                  </div>
                </button>
              </div>
            </form>
          </div>
        </section>
        {/* End. em__signTypeOne */}
      </div>
    </div>
  )
}

export default RegisterVerification;