import React, {useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Header from '../../Components/Claim/Header';
import Main from '../../Components/Claim/Main';
import Footer from '../../Components/Footer';

function ClaimView(){
  const navigate = useNavigate()
  const state = useLocation()

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  useEffect(() => {
    checkUserToken()
  },[]);

  return (
    <div>
      <div id="wrapper">
        <div id="content" className="bg-snow">
          <Header/>
          <Main uuid={state.state}/>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
export default ClaimView;