import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom"
import {claimList} from '../../Api/Claim'

function ClaimHome(){
  const navigate = useNavigate()
  const [claimRes, setClaimRes] = useState([])
  const [claim, setClaim] = useState([(
                                        <div className="content_balance bg-white border border-snow">
                                          <div className="txt">
                                            <span className="text_b color-text d-block">Claim Active</span>
                                            <small>Loading...</small>
                                            <p className="date color-snow"></p>
                                          </div>
                                          <div className="action">
                                            <button href="#" className="btn" data-toggle="modal" data-target="#mdl_submit_claim" disabled>
                                              <span className="spinner-border spinner-border-sm color-white" role="status" aria-hidden="true" />
                                            </button>
                                          </div>
                                        </div>
                                      )])

  const getClaim = async () => {
    // const userToken = localStorage.getItem('token')
    // if (userToken === null || userToken === 'undefined') {
      const userId = localStorage.getItem('user-id')
      claimList(userId).then((res) => {
        // console.log(res)
        if(res.data.length === 0){
          setClaim(
            <div className="content_balance bg-white border border-snow">
              <div className="txt">
                <span className="text_b color-text d-block">Claim Active</span>
                <small>Empty</small>
                <p className="date color-snow"></p>
              </div>
              <div className="action">
                <button href="#" className="btn" data-toggle="modal" data-target="#mdl_submit_claim">
                  Submit
                </button>
              </div>
            </div>
          )
        }else{
          setClaimRes(res.data)
        }
      })
    // }
  }

  const claimView = () => {
    setClaim(
      claimRes.map((claim, Key) => {
        return (
          <div className="content_balance border border-snow bg-white mb-2">
            <div className="txt">
              <span className="text_b color-text d-block">Claim Active</span>
              <span className="color-secondary  d-block"> {claimRes[Key].claim_number} </span>
              <small>Status</small><span className="color-primary "> {claimRes[Key].status} </span>
              <p className="date color-snow">Submit on {claimRes[Key].submit_date}</p>
            </div>
            <div className="action">
              <button className="btn" onClick={() => navigateClaimView(claimRes[Key].uuid)}>
                View Detail
              </button>
            </div>
          </div>
        )
      })
    )
  }

  const navigateClaimView = (uuid_claim) => {
    navigate('/claim-view', { state: uuid_claim })
  }

  useEffect(function() {
    claimView()
  },[claimRes])

  useEffect(function() {
    const userToken = localStorage.getItem('token')
    if (userToken != null || userToken != 'undefined') {
      getClaim()
      // console.log('getClaim()')
    }
  },[])

  return (
    <section className="npBalabce_section padding-20">
      {claim}
    </section>
  )
}
export default ClaimHome