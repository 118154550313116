import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";

function Support(){
  const mailHelp = process.env.MAIL_HELP
  const mailHelpTo = "mailto:"+mailHelp
  const navigate = useNavigate()
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("none")

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  useEffect(() => {
    checkUserToken()
  },[]);

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content" className="">
        {/* Start main_haeder */}
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              Support
            </span>
          </div>
        </header>
        {/* End.main_haeder */}
        {/* Start emPageAbout */}
        <section className="emPageAbout padding-t-60 emPage__faq">
          <div className="emBox___logo bg-snow p-0">
            <div className="cover_about">
              <img src="assets/img/faq-01.jpg" alt="" />
              <div className="content__faq_img">
                <div className="txt">
                  <h1>How can we Help?</h1>
                  <p>A fully functional search system.</p>
                </div>
                <div className="input_SaerchDefault">
                  <div className="form-group with_icon mb-0">
                    <div className="input_group">
                      <input type="search" className="form-control h-45 border-0" placeholder="write something here..." />
                      <div className="icon">
                        <svg id="Iconly_Two-tone_Search" data-name="Iconly/Two-tone/Search" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                          <g id="Search" transform="translate(2 2)">
                            <circle id="Ellipse_739" cx="8.989" cy="8.989" r="8.989" transform="translate(0.778 0.778)" fill="none" stroke="#200e32" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Line_181" d="M0,0,3.524,3.515" transform="translate(16.018 16.485)" fill="none" stroke="#200e32" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" opacity="0.4" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="em__pkLink emBlock__border bg-white margin-b-0 border-t-0">
            <ul className="nav__list mb-0">
              <li>
                <a href="#" className="item-link">
                  <div className="group">
                    <div className="icon bg-primary">
                      <svg id="Iconly_Curved_Chat" data-name="Iconly/Curved/Chat" xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19">
                        <g id="Chat" transform="translate(1.583 1.583)">
                          <path id="Stroke_11" data-name="Stroke 11" d="M.5.5H.5" transform="translate(10.54 7.744)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                          <path id="Stroke_13" data-name="Stroke 13" d="M.5.5H.5" transform="translate(7.366 7.744)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                          <path id="Stroke_15" data-name="Stroke 15" d="M.5.5H.5" transform="translate(4.192 7.744)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                          <path id="Stroke_4" data-name="Stroke 4" d="M13.515,13.514A7.921,7.921,0,0,1,4.581,15.1c-.433-.174-3.234.6-3.842-.006s.169-3.41-.006-3.842a7.918,7.918,0,1,1,12.781,2.261Z" transform="translate(0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                    <span className="path__name">Live Chat Support</span>
                  </div>
                  <div className="group">
                    <span className="short__name">9am - 9pm</span>
                    <i className="tio-chevron_right -arrwo" />
                  </div>
                </a>
              </li>
              <li>
                <a href={mailHelpTo} className="item-link">
                  <div className="group">
                    <div className="icon bg-yellow">
                      <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19">
                        <g id="Message" transform="translate(1.941 2.258)">
                          <path id="Stroke_1" data-name="Stroke 1" d="M8.828,0s-2.541,3.05-4.4,3.05S0,0,0,0" transform="translate(3.121 4.882)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M0,7.217C0,1.8,1.885,0,7.54,0s7.54,1.8,7.54,7.217-1.885,7.217-7.54,7.217S0,12.63,0,7.217Z" transform="translate(0 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                    <span className="path__name">Email Support</span>
                  </div>
                  <div className="group">
                    <span className="short__name" />
                    <i className="tio-chevron_right -arrwo" />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </section>
        <div className="w-100 bg-snow" style={{'height':'30px'}}></div>
        {/* End. emPageAbout */}
        {/* Start emSimple_main_footer */}
        <section className="emSimple_main_footer margin-t-0 border-t border-t-solid border-snow bg-white d-flex justify-content-center text-center padding-20">
          <div className="padding-t-10 padding-b-10">
            <a href="index.html" className="brand_sm margin-b-20 d-block">
              <img src="assets/img/logo-tripins.png" alt="" style={{'width':'35%', 'height':'auto'}} />
            </a>
            <h3 className="size-13 weight-400 color-secondary margin-b-10">
              Copyright © PT. OWLLAA 2022. All Rights Reserved.
            </h3>
            <p className="size-12 color-text margin-b-20">
            Contact us to discuss anything you’d like, or
            simply drop a word to say hi. We love to have a
            virtual coffees and always welcome the
            opportuinity
            </p>
            <div className="itemNetworks mt-2 emBlock__border">
              <a href="#" className="btn facebook">
                <img src="assets/img/icon/facebook.svg" alt="" />
              </a>
              <a href="#" className="btn instagram">
                <img src="assets/img/icon/instagram.svg" alt="" />
              </a>
              <a href="#" className="btn youtube">
                <img src="assets/img/icon/youtube.svg" alt="" />
              </a>
              <a href="#" className="btn twitter">
                <i className="ri-twitter-fill color-twitter" />
              </a>
              <button type="button" className="btn share" data-toggle="modal" data-target="#mdllButtons_share">
                <i className="ri-share-forward-box-line color-green" />
              </button>
            </div>
            <div className="link_privacy env-pb">
              <a href="#" className="btn">Privacy Policy</a>
              <a href="#" className="btn">Terms of Uses</a>
            </div>
          </div>
        </section>
        {/* End. emSimple_main_footer */}
      </div>
      {/* <Footer/> */}
    </div>
  );
}
export default Support;