import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {personalData, updateProfile} from '../../Api/Auth'

function PersonalDetails(){
  const navigate = useNavigate()
  // const userId = useRef(location.state.userId)

  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("none")
  const [validation, setValidation] = useState("")

  const inputRef = useRef(null);
  const [idUser, setIdUser] = useState("")
  const [fullname, setFullname] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  const getPersonalData = async () => {
    const userId = localStorage.getItem('user-id')
    personalData(userId).then((res) => {
      if(res.data.length != 0){
        setIdUser(res.data.id_users)
        setFullname(res.data.fullname)
        setPhoneNumber(res.data.phone_number)
        setEmail(res.data.email)
      }
    })
  }

  const updateHandler = async (e) => {
    setValidation("")
    setLoaderDisplayBg0('flex')
    e.preventDefault()
    setTimeout(function() { 
      const formData = new FormData();
      formData.append('id_users', idUser);
      formData.append('fullname', fullname);
      formData.append('phone_number', phoneNumber);
      formData.append('email', email);
      updateProfile(formData).then((res) => {
        if(res.response === 'SUCCESS'){
          localStorage.setItem('fullname', fullname);
          setValidation(res.message)
          setTimeout(function() { 
            setValidation('')
            navigate('/personal-details')
          }, 2000)
        }else{
          setValidation(res.message)
          inputRef.current.focus()
        }
        setLoaderDisplayBg0('none')
      })
    }, 1000)
  }

  useEffect(() => {
    checkUserToken()
    getPersonalData()
    inputRef.current.focus()
  },[]);

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content" className="">
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/profile">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              Update Profile
            </span>
          </div>
          <div class="em_side_right">
            <a href="/home" class="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all text-primary">
              Home
            </a>
          </div>
        </header>
        {/* form */}
        <section className="padding-20">
          <form onSubmit={updateHandler} className="padding-t-70">
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed">
                <div className="input_group">
                  <input ref={inputRef} type="text" className="form-control" defaultValue="Calvin Bell" id="username" placeholder="Enter full name" value={fullname} onChange={(e) => setFullname(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Message" transform="translate(2.248 2.614)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                </div>
                <label htmlFor="username">Full name</label>
              </div>
            </div>
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed">
                <div className="input_group">
                  <input type="email" className="form-control" defaultValue="orinostudio@gmail.com" id="email" placeholder="example@mail.com" value={email} onChange={(e) => setEmail(e.target.value)}  required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Message" transform="translate(2.248 2.614)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                </div>
                <label htmlFor="email">Email Address</label>
              </div>
            </div>
            <div className="margin-b-30">
              <div className="form-group input-lined lined__iconed">
                <div className="input_group">
                  <input type="text" className="form-control" defaultValue="orinostudio@gmail.com" id="email" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Message" transform="translate(2.248 2.614)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                </div>
                <label htmlFor="email">Phone Number</label>
              </div>
            </div>
            <div className="alert alert-light text-center" role="alert">
              {
                validation !== "" ? (<div>{validation}</div>) : ''
              }
            </div>
            <div className="buttons__footer text-center">
              <div className="env-pb">
                <button type="submit" className="btn bg-primary rounded-pill btn__default">
                  <span className="color-white">Save Changes</span>
                  <div className="icon rounded-circle">
                    <i className="tio-chevron_right" />
                  </div>
                </button>
              </div>
            </div>
          </form>
        </section>
        {/* buttons__footer */}
      </div>
      {/* <Footer/> */}
    </div>
  );
}
export default PersonalDetails;