import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import ClaimHistory from '../../Components/Claim/ClaimHistory';
import Footer from '../../Components/Footer';

function ClaimView(){
  const navigate = useNavigate()
  const state = useLocation()
  
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  useEffect(() => {
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
    checkUserToken()
  },[]);

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content" className="bg-snow">
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Home</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              Your Claim
            </span>
          </div>
        </header>
        <ClaimHistory/>
      </div>
      <Footer/>
    </div>
  );
}

export default ClaimView;