import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";

function FrequentQuestions(){
  const navigate = useNavigate()
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("none")

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  useEffect(() => {
    checkUserToken()
  },[]);

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content" className="">
        {/* Start main_haeder */}
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
             FAQ
            </span>
          </div>
        </header>
        {/* End.main_haeder */}
        {/* Start emPageAbout */}
        <section className="emPageAbout padding-t-60 emPage__faq">
          <div className="content_text bg-white emBlock__border margin-b-0">
            <h2 className="size-20 weight-500 color-secondary mb-2">Frequent Questions</h2>
            <p>
              We get asked these questions a lot, so we made this small section to help you out identifying
              what you need faster.
            </p>
          </div>
          {/* Start emPage___profile */}
          <div className="w-100 bg-snow" style={{'height':'30px'}}></div>
          <div className="em__pkLink accordion bg-white emBlock__border border-bottom-0 pt-3" id="accordionExample5">
            <ul className="nav__list with_border fullBorder">
              <li>
                <div id="headingOne-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseOne-text" aria-expanded="true" aria-controls="collapseOne-text">
                    <div className="group">
                      <div>
                        <span className="path__name text-transform-none">Is this built with
                          Boostrap?</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-add iocn__plus -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseOne-text" className="collapse show" aria-labelledby="headingOne-text" data-parent="#accordionExample5">
                  <div className="card-body">
                    <p className="mb-0 size-15 color-text">
                      Some placeholder content for the first accordion panel. This panel is shown
                      by
                      default, thanks to the .show class.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div id="headingTwo-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseTwo-text" aria-expanded="false" aria-controls="collapseTwo-text">
                    <div className="group">
                      <div>
                        <span className="path__name text-transform-none">Is this a PWA?</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-add iocn__plus -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseTwo-text" className="collapse" aria-labelledby="headingTwo-text" data-parent="#accordionExample5">
                  <div className="em__pkLink">
                    <div className="card-body">
                      <p className="mb-0 size-15 color-text">
                        Yes. Our item is a PWA. We have a servier worker and a manifest.json file
                        ready and prepared for you to use the item offline.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div id="headingThree-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseThree-text" aria-expanded="false" aria-controls="collapseThree-text">
                    <div className="group">
                      <div>
                        <span className="path__name text-transform-none">What CSS framework this theme
                          use?</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-add iocn__plus -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseThree-text" className="collapse" aria-labelledby="headingThree-text" data-parent="#accordionExample5">
                  <div className="card-body">
                    <p className="mb-0 size-15 color-text">
                      We are using the latest Bootstrap 4.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div id="headingFour-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseFour-text" aria-expanded="false" aria-controls="collapseFour-text">
                    <div className="group">
                      <div>
                        <span className="path__name text-transform-none">Is this a WordPres
                          Theme?</span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-add iocn__plus -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseFour-text" className="collapse" aria-labelledby="headingFour-text" data-parent="#accordionExample5">
                  <div className="card-body">
                    <p className="mb-0 size-15 color-text">
                      No. Our item is an HTML, CSS3, and JS Site Template. You can however convert it
                      to a WordPress Theme.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div id="headingFive-text">
                  <div className="item-link main_item" data-toggle="collapse" data-target="#collapseFive-text" aria-expanded="false" aria-controls="collapseFive-text">
                    <div className="group">
                      <div>
                        <span className="path__name text-transform-none">
                          What can I do with this template?
                        </span>
                      </div>
                    </div>
                    <div className="group">
                      <span className="short__name" />
                      <i className="tio-add iocn__plus -arrwo" />
                    </div>
                  </div>
                </div>
                <div id="collapseFive-text" className="collapse" aria-labelledby="headingFive-text" data-parent="#accordionExample5">
                  <div className="card-body">
                    <p className="mb-0 size-15 color-text">
                      You can make mobile websites or progressive web apps for mobile devices.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/* End. emPage___profile  */}
        </section>
      </div>
      {/* <Footer/> */}
    </div>
  );
}
export default FrequentQuestions;