import React from 'react';

// class Header extends Component{

function Header(){
  // render(){
    return (
      // <div>
      //   {/* Start main_haeder */}
      //   <header className="main_haeder header-sticky multi_item">
      //     <div className="em_menu_sidebar">
      //       <button type="button" className="btn btn_menuSidebar item-show" data-toggle="modal" data-target="#mdllSidebarMenu-background">
      //         <i className="ri-menu-fill" />
      //       </button>
      //     </div>
      //     <div className="em_brand">
      //       <a href="index.html" className="h3 text-white">
      //         {/* <img src="assets/img/logo-b.svg" alt="" /> */}
      //         Tripins
      //       </a>
      //     </div>
      //     <div className="em_side_right">
      //       <button className="btn rounded-circle share-button bg-transparent margin-r-10" data-toggle="modal" data-target="#mdllButtons_share">
      //         <i className="ri-share-forward-box-line" />
      //       </button>
      //       <button type="button" className="btn btn_meunSearch" id="saerch-On-header">
      //         <i className="ri-search-2-line" />
      //       </button>
      //     </div>
      //   </header>
      //   {/* End.main_haeder */}
      // </div>

      <header className="main_haeder header-sticky multi_item header-white">
        <div className="em_menu_sidebar">
          <button type="button" className="btn btn_menuSidebar item-show" data-toggle="modal" data-target="#mdllSidebarMenu-background">
            <i className="ri-menu-fill" />
          </button>
        </div>
        <div className="em_brand">
          <a href="index.html" className="h4 text-white">
            {/* <img src="assets/img/logo-b.svg" alt="" /> */}
            Tripins
          </a>
        </div>
        <div className="em_side_right">
            <button className="btn rounded-circle share-button bg-transparent" data-toggle="modal"
                data-target="#mdllButtons_share">
                <i className="ri-share-forward-box-line"></i>
            </button>
            {/* <button type="button" className="btn btn_meunSearch" id="saerch-On-header">
                <i className="ri-search-2-line"></i>
            </button> */}
        </div>
      </header>
    );
  // }
}
export default Header;