import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import ClaimHistory from '../../Components/Claim/ClaimHistory';
import Footer from '../../Components/Footer';

function MyProfile(){
  const navigate = useNavigate()
  const state = useLocation()
  
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const userFullname = localStorage.getItem('fullname')
  const userEmail = localStorage.getItem('email')

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  useEffect(() => {
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
    checkUserToken()
  },[]);

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content" className="bg-snow">
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Home</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              My Profile
            </span>
          </div>
        </header>
        <div>
          <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 10%)'}}>
            <div className="spinner_flash" />
          </section>
          <section className="emPageAbout padding-t-60">
            <div className="emBox___logo bg-snow">
              <div className="brand mb-0">
                <h4 className='text-info'>{userFullname}</h4>
              </div>
              <p className="color-text size-12 m-0">{userEmail}</p>
            </div>
            <div className="content_text bg-white emBlock__border py-0">
              <div className="em_group full__border">
                <a href="/personal-details" className="itemListClassic">
                  <div className="txt">
                    <h3> Personal Details </h3>
                  </div>
                  <div className="sideRight">
                    <i className="tio-chevron_right" />
                  </div>
                </a>
                <a href="/change-password" className="itemListClassic">
                  <div className="txt">
                    <h3>Security & Password</h3>
                  </div>
                  <div className="sideRight">
                    <i className="tio-chevron_right" />
                  </div>
                </a>
                {/* <a href="https://themeforest.net/user/orinostu/portfolio" target="_blank" className="itemListClassic">
                  <div className="txt">
                    <h3>Our Projects</h3>
                  </div>
                  <div className="sideRight">
                    <i className="tio-chevron_right" />
                  </div>
                </a>
                <a href="page-contact.html" className="itemListClassic">
                  <div className="txt">
                    <h3>Have a Question?</h3>
                  </div>
                  <div className="sideRight">
                    <i className="tio-chevron_right" />
                  </div>
                </a> */}
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
export default MyProfile;