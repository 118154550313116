import React, {useEffect, useState, useRef} from 'react'
import {detailClaim, uploadDocument, claimFormDocument as reqClaimFormDocument, spkClaim, spkPdf, appealClaim, sendResponseSpk, documentHardcopy, InputDocumentHardcopy, detailClaimForm, paymentClaim, spkApprove} from '../../Api/Claim';

function Main(props){
  const baseUrl = process.env.REACT_APP_BASEURL
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [tabOpen, setTabOpen] = useState('')
  const [dataTabOpen, setDataTabOpen] = useState([])
  const claimForm = useRef()
  const [claimStatus, setClaimStatus] = useState()
  const [claimFormId, setClaimFormId] = useState('')
  const [claimFormName, setClaimFormName] = useState('')
  const [claimFormStatus, setClaimFormStatus] = useState('')

  const fileClaim = useRef([])
  const documentTypeUploadRef = useRef(null)
  const [dataDetailClaim, setDataDetailClaim] = useState([])
  const [dataDocumentClaim, setDataDocumentClaim] = useState([])
  let docMap=1
  let documentNotUpload=0

  const spkResponse = useRef()
  const csuStatus = useRef()
  const [dataAppeal, setDataAppeal] = useState([])

  const message = useRef()
  const sendHardcopyDate = useRef()
  const expeditionService = useRef()
  const receiptNumber = useRef()
  
  const handleTabOpen = () => {
    if(tabOpen === 'tabDocument'){
      return tabDocumentClaim()
    }else if(tabOpen === 'tabSpk'){
      return getSpkClaim(claimForm.current)
    }else if(tabOpen === 'tabHardcopy'){
      return hardcopyClaim()
    }else if(tabOpen === 'tabPayment'){
      return paymentProcess()
    }
  }

  const handleFileSelect = (event) => {
    fileClaim.current = event.target.files[0];
  }

  const getDetailClaim = async () => {
    detailClaim(props.uuid).then((res) => {
      // console.log(res)
      setDataDetailClaim(res)
      setClaimFormId(res.data[1]['formlist'][0].id_claimform)
      setClaimStatus(res.data[0]['detail_claim'][0].status)
      setClaimFormName(res.data[1]['formlist'][0].insured_name)
      setClaimFormStatus(res.data[1]['formlist'][0].status_form)
      getClaimFormDocument(res.data[1]['formlist'][0].id_claimform)
    })
  }

  const tabDocumentClaim = () => {
    setDataTabOpen(
      <div className="col-md-12">
        <div className="border rounded padding-10" style={{minHeight: "300px"}}>
        <div className="emTitle_co p-2">
          <h2 className="size-16 weight-500 color-secondary mb-1">Document List</h2>
          <p className="size-12 color-text m-0">
            Please upload your document claim
          </p>
        </div>
          {dataDocumentClaim}
        </div>
      </div>
    )
  }

  const formUpload = (documentClaim) => {
    return(
      <div>
        <form onSubmit={uploadHandler}>
          <div className="form-group">
            <input type="hidden" name="documentType" ref={documentTypeUploadRef} value={documentClaim} />
            <input type="file" className="form-control-file" onChange={handleFileSelect} />
          </div>
          <button type="submit" className='btn btn-sm btn-primary btn-block'>Upload</button>
        </form>
      </div>
    )
  }

  const getClaimFormDocument = async (idClaimForm) => {
    claimForm.current = idClaimForm
    setClaimFormId(idClaimForm)
    detailClaimForm(idClaimForm).then((res) => {
      setClaimFormName(res.data[0]['detail_claim_form'][0].insured_name)
      setClaimFormStatus(res.data[0]['detail_claim_form'][0].status_form)
    })

    setDataTabOpen(
      <div className="col-md-12">
        <div className="border rounded padding-10" style={{minHeight: "300px"}}>
        <div className="emTitle_co p-2">
          <h2 className="size-16 weight-500 color-secondary mb-1">Document List</h2>
          <p className="size-12 color-text m-0">
            Please upload your document claim
          </p>
        </div>
          <span className="d-block w-100 p-1 text-center text-muted">Loading...</span>
        </div>
      </div>
    )

    if(dataDetailClaim.length !== 0){
      dataDetailClaim.data[1]['formlist'].map((formList, formKey) => {
        if(formList.id_claimform === idClaimForm){
          setClaimFormName(formList.insured_name)
        }
      })
    }

    reqClaimFormDocument(idClaimForm).then((res) => {
      setDataDocumentClaim(
        <div className="emPage___profile accordion bg-white" id="accordionExample">
          <div className="emBody__navLink with__border">
            <ul>
              {
                res.data[0]['document_claim'].map((documentClaim, documentClaimKey) => {
                  documentNotUpload = 1
                  docMap = 1
                  return (
                    <li className="item" key={documentClaimKey}>
                      <div id={'heading'+documentClaimKey}>
                        <div className="nav-link main_item px-2" data-toggle="collapse" data-target={'#collapse'+documentClaimKey} aria-expanded="false" aria-controls={'collapse'+documentClaimKey}>
                          <div className="media align-items-center">
                            <div className="ico">
                              <svg id="Iconly_Two-tone_Document" data-name="Iconly/Two-tone/Document" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                                <g id="Document" transform="translate(3 2)">
                                  <path id="Stroke_1" data-name="Stroke 1" d="M7.22.5H0" transform="translate(5.496 13.723)" fill="none" stroke="#292e34" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" opacity="0.4" />
                                  <path id="Stroke_2" data-name="Stroke 2" d="M7.22.5H0" transform="translate(5.496 9.537)" fill="none" stroke="#292e34" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" opacity="0.4" />
                                  <path id="Stroke_3" data-name="Stroke 3" d="M2.755.5H0" transform="translate(5.496 5.36)" fill="none" stroke="#292e34" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" opacity="0.4" />
                                  <path id="Stroke_4" data-name="Stroke 4" d="M12.158,0,4.469,0A4.251,4.251,0,0,0,0,4.607v9.2A4.254,4.254,0,0,0,4.506,18.41l7.689,0a4.252,4.252,0,0,0,4.47-4.6v-9.2A4.255,4.255,0,0,0,12.158,0Z" transform="translate(0.751 0.75)" fill="none" stroke="#292e34" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                                </g>
                              </svg>
                            </div>
                            <div className="media-body">
                              <div className="txt">
                                <span className="size-12 font-weight-bold">{documentClaim.name_document}</span>
                                {/* <span className="dot blink d-inline-block ml-1"></span> */}
                              </div>
                            </div>
                          </div>
                          <div className="side_right">
                            <i className="tio-add iocn__plus" />
                          </div>
                        </div>
                      </div>
                      <div id={'collapse'+documentClaimKey} className="collapse border" aria-labelledby={'heading'+documentClaimKey} data-parent="#accordionExample">
                        <div className="card-body">
                          {
                            res.data[1]['document_upload'].map((documentUpload, documentUploadKey) => {
                              if(docMap === 1){
                                if(documentClaim.documentclaim === documentUpload.doc_type){
                                  documentNotUpload = 0
                                  docMap = 0
                                  return (
                                    <div key={documentUploadKey}>
                                      <div className="margin-b-0">
                                        <label className='d-block mb-0'>Upload Date</label>
                                        <label className='text-primary'>{documentUpload.upload_date}</label>
                                      </div>
                                      <div className="margin-b-0">
                                        <label className='d-block mb-0'>Document Name</label>
                                        <label className='text-primary text-break'>{documentUpload.file_name}</label>
                                      </div>
                                      <div className="margin-b-0">
                                        <label className='d-block mb-0'>Document Status</label>
                                        <label className='text-primary text-break'>{documentUpload.document_completed == null ? 'Pending Checking' : (documentUpload.document_completed == 1 ? 'Ok' : (documentUpload.document_completed == 2 ? 'Reject - Need Reupload' : ''))}</label>
                                      </div>
                                      <div className="row mt-3">
                                        <div className="col-12">
                                          <a href={baseUrl+"/document-view/"+idClaimForm+"/"+documentUpload.file_name} target="_blank" className="btn btn-sm btn-warning btn-block">View</a>
                                        </div>
                                      </div>
                                      {
                                        documentUpload.document_completed == 2 ?
                                        (
                                          <div className="row margin-b-0 mt-3 pt-2 border-top">
                                            <div className="col-12">
                                              <p className="mb-2 text-muted">Re-Upload Document</p>
                                              {formUpload(documentClaim.documentclaim)}
                                            </div>
                                          </div>
                                        ) : ''
                                      }
                                    </div>
                                  )
                                }else{
                                  documentNotUpload = 1
                                }
                              }
                            })
                          }
                          {
                            documentNotUpload === 1 ? formUpload(documentClaim.documentclaim) : ''
                          }
                        </div>
                      </div>
                    </li>
                  );
                })
              } 
            </ul>
          </div>
        </div>
      )
      
      if(tabOpen === 'tabDocument'){
        tabDocumentClaim()
      }else{
        setTabOpen('tabDocument')
      }
    })
  }

  const uploadHandler = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append("claim_form", claimForm.current);
    formData.append("document_type", e.target.documentType.value );
    formData.append("file", fileClaim.current);
    uploadDocument(formData).then((res) => {
      getClaimFormDocument(claimForm.current)
    })
  }

  const getSpkClaim = (idClaimForm) => {
    if(claimFormStatus < 9){
      setDataTabOpen(
        <div className="col-md-12">
          <div className="border rounded padding-10" style={{minHeight: "300px"}}>
          <div className="emTitle_co p-2">
            <h2 className="size-16 weight-500 color-secondary mb-1">SPK Claim</h2>
            <p className="size-12 color-text m-0">
              Guarantee letter
            </p>
          </div>
            <span className="d-block w-100 p-1 text-center text-muted">SPK is not avilable for this <b>Status</b></span>
          </div>
        </div>
      )
    }else{
      spkClaim(idClaimForm).then((res) => {
        // console.log(res)
        var link_pdf = baseUrl+"/pdf-spk/"+idClaimForm+"/"+res.data[0]['spk'].spk_file
        spkResponse.current = res.data[0]['spk'].response
        csuStatus.current = res.data[0]['spk'].csu_status
        var memberApproval = res.data[0]['spk'].response !== null ? (res.data[0]['spk'].response === '1' ? 'Approved' : 'Appeal') : 'Pending Response'
        setDataTabOpen(
          <div className="col-md-12">
            <div className="border rounded padding-10" style={{minHeight: "300px"}}>
              <div className="emTitle_co p-2">
                <h2 className="size-16 weight-500 color-secondary mb-1">SPK Claim</h2>
                <p className="size-12 color-text m-0">
                  Guarantee letter
                </p>
              </div>
              <div className="emPage___profile accordion bg-white" id="accordionExample">
                <div className="emBody__navLink with__border">
                  <section className="padding-10 pt-0 bg-white">
                    <div className="">
                      <div className="margin-b-0">
                        <small>Name</small><br/>
                        <label>{res.data[0]['spk'].insured_name}</label>
                      </div>
                      <div className="margin-b-0">
                        <small>Claim Benefit</small><br/>
                        <label>{res.data[0]['spk'].benefit_name}</label>
                      </div>
                      <div className="margin-b-0">
                        <small>Status Pengajuan Claim</small><br/>
                        <label>{res.data[0]['spk'].csu_status_name}</label>
                      </div>
                      <div className="margin-b-0">
                        <small>Member Approval</small><br/>
                        <label>{memberApproval}</label>
                      </div>
                      {/* <div className="margin-b-0">
                        <small>SPK Response</small><br/>
                        <label>{res.data[0]['spk'].response == null ? 'Pending Response Member' : ((res.data[0]['spk'].response == 1 ? 'Approved' : 'Appeal'))}</label>
                      </div> */}
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <a className="btn btn-sm btn-primary btn-block mb-2" href={link_pdf} target="_blank">View SPK</a>
                      </div>
                      <div className="col-12">
                        {res.data[0]['spk'].response === '1' ? (
                          <button className="btn btn-sm btn-primary btn-block mb-2" disabled='true'>Approve</button>
                        ) : (
                          <button className="btn btn-sm btn-primary btn-block mb-2" data-toggle="modal" data-target="#mdl_spk_approve">Approve</button>
                        )}
                      </div>
                      <div className="col-12">
                        <button className="btn btn-sm btn-warning btn-block" data-toggle="modal" data-target="#mdl_spk_appeal" onClick={event => formAppeal()}>Appeal</button>
                      </div>
                    </div>
                  </section>
  
                  {/* Modal Request */}
                  <div className="modal transition-bottom screenFull defaultModal mdlladd__rate fade" id="mdl_spk_appeal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content" style={{height: "85vh"}}>
                        <div className="modal-header padding-l-20 padding-r-20 justify-content-center">
                          <div className="itemProduct_sm">
                            <h1 className="size-18 weight-600 color-secondary m-0">Form Appeal</h1>
                          </div>
                          {/* here is close button */}
                          <div className="absolute right-0 padding-r-20">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <i className="tio-clear" />
                            </button>
                          </div>
                        </div>
                        <div id="content modal-body">
                          {dataAppeal}
                        </div>
                      </div>
                    </div>
                  </div>
  
                  {/* Modal confirm__transfer */}
                  <div className="modal transition-bottom screenFull defaultModal confirm__transfer mdlladd__rate fade" id="mdl_spk_approve" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header padding-l-20 padding-r-20 justify-content-center">
                          <div className="itemProduct_sm">
                            <h1 className="size-18 weight-600 color-secondary m-0">Confirm Approve</h1>
                          </div>
                          <div className="absolute right-0 padding-r-20">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <i className="tio-clear" />
                            </button>
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="padding-t-20 padding-b-30">
                            {/* <div className="trans__number margin-t-20 padding-b-30">
                              <h3>150.00 <span>USD</span></h3>
                              <p>Amount</p>
                            </div> */}
                            <p className="color-text size-13 text-center mb-0">Are you sure want to approve this SPK ?</p>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn w-100 bg-primary m-0 color-white h-52 d-flex align-items-center rounded-8 justify-content-center" onClick={event => approveSpk()} data-dismiss="modal" aria-label="Close">
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
  }

  const formAppeal = async () => {
    setDataAppeal([])
    appealClaim(claimForm.current).then((res) => {
      // console.log('appeal')
      // console.log(res.data[0]['appeal'])
      setDataAppeal(
        <section className="emPage__conversation padding-b-80">
          {
            res.data[0]['appeal'].map((appeal, appealkey) => {
              if(parseInt(appeal.appeal_from) === 1){
                // console.log('from : '+appeal.appeal_from)
                return (
                  <div className="item_user __me">
                    <div className="media">
                      <div className="media-body">
                        <div className="content_sms">
                          <p className="item_msg float-right d-block" style={{width : '85%'}}>
                            {appeal.remarks}
                          </p>
                          <div className="time w-75 float-right text-right d-block">
                            {/* <a href={baseUrl+"/pdf-spk/"+appeal.csu_ref} target="_blank" className="d-inline">SPK Ref</a> |   */}
                            <span className='d-inline'> {appeal.appeal_datetime}</span>
                            {/* <div className="icon ml-1 d-inline">
                              <i className="ri-check-double-line color-primary size-14" />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }else if(parseInt(appeal.appeal_from) === 2){
                return (
                  <div className="item_user">
                    <div className="media">
                      <div className="media-body">
                        <div className="content_sms">
                          <p className="item_msg text-break" style={{width : '85%'}}>
                            {appeal.remarks}
                          </p>
                          {
                            appeal.with_csu === '1' ? (
                              (
                                <a href={baseUrl+"/pdf-spk/"+appeal.csu_ref} target="_blank" className="btn btn-sm btn_file_down">
                                  <i className="tio-download_to"/>
                                  <span>SPK-{appeal.csu_ref}.pdf</span>
                                </a>
                              )
                            ) : ''
                          }
                          <div className="time w-75 float-left text-left d-block">
                            <span className='d-inline'>{appeal.appeal_datetime}</span> |
                            {/* <a href={baseUrl+"/pdf-spk/"+appeal.csu_ref} target="_blank" className="d-inline"> SPK Ref</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })
          }
          {
            spkResponse.current === '2' || spkResponse.current === null ? (
              <div className="env-pb bg-white fixed w-100 bottom-0">
                {/* <div className="bk_footer_input emBK__buttonsShare"> */}
                  <form onSubmit={appealHandler} className="bk_footer_input emBK__buttonsShare">
                    <div className="form-group w-100 m-0">
                      <input type="text" className="form-control w-100" style={{width : '90%'}} placeholder="Type a message here" onChange={(e) => message.current = (e.target.value)} autoFocus='true' required />
                    </div>
                    <button type="submit" className="btn btn_defSend rounded-10">
                      <svg id="Iconly_Bulk_Send" data-name="Iconly/Bulk/Send" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                        <g id="Send" transform="translate(1.667 1.667)">
                          <path id="Fill_1" data-name="Fill 1" d="M16.19.482A1.615,1.615,0,0,0,14.581.065L1.173,3.939A1.6,1.6,0,0,0,.02,5.2,1.863,1.863,0,0,0,.855,6.94L5.048,9.5a1.09,1.09,0,0,0,1.341-.159l4.8-4.8a.613.613,0,0,1,.883,0,.629.629,0,0,1,0,.883l-4.809,4.8A1.092,1.092,0,0,0,7.1,11.565l2.562,4.208a1.668,1.668,0,0,0,1.592.774,1.62,1.62,0,0,0,1.358-1.15L16.59,2.09a1.619,1.619,0,0,0-.4-1.608" transform="translate(0 0)" fill="#fff" />
                          <path id="Combined_Shape" data-name="Combined Shape" d="M3.97,6.355a.625.625,0,0,1,0-.883L5.108,4.333a.625.625,0,1,1,.884.884L4.854,6.355a.625.625,0,0,1-.883,0ZM3.317,3.2a.625.625,0,0,1,0-.884L4.455,1.176a.625.625,0,0,1,.884.884L4.2,3.2a.625.625,0,0,1-.883,0ZM.183,2.2a.625.625,0,0,1,0-.884L1.321.182a.625.625,0,0,1,.884.884L1.066,2.2a.625.625,0,0,1-.884,0Z" transform="translate(0.217 9.952)" fill="#fff" opacity="0.4" />
                        </g>
                      </svg>
                    </button>
                  </form>
                {/* </div> */}
              </div>
            ) : (
              <div className="env-pb bg-white fixed w-100 bottom-0">
                {/* <div className="bk_footer_input emBK__buttonsShare"> */}
                  <form onSubmit={appealHandler} className="bk_footer_input emBK__buttonsShare">
                    <div className="form-group w-100 m-0">
                      <input type="text" className="form-control w-100" placeholder="Type a message here" disabled />
                    </div>
                    <button type="button" className="btn btn_defSend rounded-10" disabled>
                      <svg id="Iconly_Bulk_Send" data-name="Iconly/Bulk/Send" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                        <g id="Send" transform="translate(1.667 1.667)">
                          <path id="Fill_1" data-name="Fill 1" d="M16.19.482A1.615,1.615,0,0,0,14.581.065L1.173,3.939A1.6,1.6,0,0,0,.02,5.2,1.863,1.863,0,0,0,.855,6.94L5.048,9.5a1.09,1.09,0,0,0,1.341-.159l4.8-4.8a.613.613,0,0,1,.883,0,.629.629,0,0,1,0,.883l-4.809,4.8A1.092,1.092,0,0,0,7.1,11.565l2.562,4.208a1.668,1.668,0,0,0,1.592.774,1.62,1.62,0,0,0,1.358-1.15L16.59,2.09a1.619,1.619,0,0,0-.4-1.608" transform="translate(0 0)" fill="#fff" />
                          <path id="Combined_Shape" data-name="Combined Shape" d="M3.97,6.355a.625.625,0,0,1,0-.883L5.108,4.333a.625.625,0,1,1,.884.884L4.854,6.355a.625.625,0,0,1-.883,0ZM3.317,3.2a.625.625,0,0,1,0-.884L4.455,1.176a.625.625,0,0,1,.884.884L4.2,3.2a.625.625,0,0,1-.883,0ZM.183,2.2a.625.625,0,0,1,0-.884L1.321.182a.625.625,0,0,1,.884.884L1.066,2.2a.625.625,0,0,1-.884,0Z" transform="translate(0.217 9.952)" fill="#fff" opacity="0.4" />
                        </g>
                      </svg>
                    </button>
                  </form>
                {/* </div> */}
              </div>
            )
          }
        </section>
      )
    })
  }
  
  const appealHandler = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('id_claimform', claimForm.current)
    formData.append('spk_feedback', 2)
    formData.append('remarks', message.current)
    sendResponseSpk(formData).then((res) => {
      formAppeal()
      e.target.reset()
    })
  }

  const approveSpk = async () => {
    setLoaderDisplayBg0('flex')
    setDataTabOpen('')
    const formData = new FormData()
    formData.append('id_claimform', claimForm.current)
    formData.append('spk_feedback', 1)
    setTimeout(function() { 
    sendResponseSpk(formData).then((res) => {
      getSpkClaim(claimForm.current)
      setLoaderDisplayBg0('none')
      detailClaimForm(claimForm.current).then((res) => {
        setClaimFormName(res.data[0]['detail_claim_form'][0].insured_name)
        setClaimFormStatus(res.data[0]['detail_claim_form'][0].status_form)
        setClaimStatus(res.data[0]['detail_claim_form'][0].status_claim)
      })
    })
    }, 1000)
  }

  const hardcopyClaim = async () => {
    if(claimFormStatus < 11 || parseInt(claimStatus) === 18){
      setDataTabOpen(
        <div className="col-md-12">
          <div className="border rounded padding-10" style={{minHeight: "300px"}}>
          <div className="emTitle_co p-2">
            <h2 className="size-16 weight-500 color-secondary mb-1">Document Hardcopy Claim</h2>
            <p className="size-12 color-text m-0">
              Please send hardcopy claim to headoffice
            </p>
          </div>
            <span className="d-block w-100 p-1 text-center text-muted">Hardcopy Claim is not avilable for this <b>Status</b></span>
          </div>
        </div>
      )
    }else{
      if(parseInt(claimStatus) !== 18){
        documentHardcopy(claimForm.current).then((res) => {
          var statusClass = res.data[0]['spk'].hardcopy_completed === null ? (res.data[0]['spk'].hardcopy_send_date === null ? 'text-danger' : 'text-primary') : 'text-success'
          var statusDocument = res.data[0]['spk'].hardcopy_completed === null ? (res.data[0]['spk'].hardcopy_send_date === null ? 'Pending Send by Member' : 'Pending Review') : 'Completed'
          
          var hardcopyView
          if(res.data[0]['spk'].hardcopy_send_date === null){
            hardcopyView = ((
              <form onSubmit={hardcopyHandler} className="mt-3">
                <div className="form-group input-lined">
                  <input type="date" className="form-control" placeholder="Send date" min="1997-01-01" max="2030-12-31" onChange={(e) => sendHardcopyDate.current = (e.target.value)} required />
                  <label>Send Date</label>
                </div>
                <div className="form-group input-lined">
                  <input type="text" className="form-control" placeholder="jne/tiki/pos..." onChange={(e) => expeditionService.current = (e.target.value)} required />
                  <label>Expedition Service</label>
                </div>
                <div className="form-group input-lined">
                  <input type="text" className="form-control" placeholder="Resi Number" onChange={(e) => receiptNumber.current = (e.target.value)} required />
                  <label>Receipt Number</label>
                </div>
                <button type="submit" className="btn w-100 bg-primary m-0 color-white d-flex align-items-center rounded-8 justify-content-center">
                  Save
                </button>
              </form>
            ))
          }else{
            hardcopyView = ((
              <div>
                <div className="margin-b-0">
                  <small>Send Date</small><br/>
                  <label className=''>{res.data[0]['spk'].hardcopy_send_date}</label>
                </div>
                <div className="margin-b-0">
                  <small>Expedition Service</small><br/>
                  <label className=''>{res.data[0]['spk'].expedition_service}</label>
                </div>
                <div className="margin-b-0">
                  <small>Receipt Number</small><br/>
                  <label className=''>{res.data[0]['spk'].receipt_number}</label>
                </div>
              </div>
            ))
          }
    
          setDataTabOpen(
            <div className="col-md-12">
              <div className="border rounded padding-10" style={{minHeight: "300px"}}>
                <div className="emTitle_co p-2">
                  <h2 className="size-16 weight-500 color-secondary mb-1">Document Hardcopy Claim</h2>
                  <p className="size-12 color-text m-0">
                    Please send hardcopy claim to headoffice
                  </p>
                </div>
                  <div className="emPage___profile accordion bg-white" id="accordionExample">
                    <div className="emBody__navLink with__border">
                      <section className="padding-10 pt-0 pb-0 bg-white">
                        <div className="">
                          <div className="margin-b-0">
                            <small>Status Document</small><br/>
                            <label className={statusClass}>{statusDocument}</label>
                          </div>
                          {hardcopyView}
                        </div>
                      </section>
                    </div>
                  </div>
              </div>
            </div>
          )
        })
      }else{
        setDataTabOpen(
          <div className="col-md-12">
            <div className="border rounded padding-10" style={{minHeight: "300px"}}>
            <div className="emTitle_co p-2">
              <h2 className="size-16 weight-500 color-secondary mb-1">Document Hardcopy Claim</h2>
              <p className="size-12 color-text m-0">
                Please send hardcopy claim to headoffice
              </p>
            </div>
              <span className="d-block w-100 p-1 text-center text-muted">Hardcopy Claim is not avilable for this <b>Status</b></span>
            </div>
          </div>
        )
      }
    }
  }
  
  const hardcopyHandler = async (e) => {
    e.preventDefault()
    setLoaderDisplayBg0('flex')
    setDataTabOpen('')
    const formData = new FormData()
    formData.append('id_claimform', claimForm.current)
    formData.append('send_date', sendHardcopyDate.current)
    formData.append('expedition_service', expeditionService.current)
    formData.append('receipt_number', receiptNumber.current)
    InputDocumentHardcopy(formData).then((res) => {
      setLoaderDisplayBg0('none')
      hardcopyClaim()
    })
  }

  const paymentProcess = async () => {
    detailClaimForm(claimFormId).then((res) => {
      setClaimFormName(res.data[0]['detail_claim_form'][0].insured_name)
      setClaimFormStatus(res.data[0]['detail_claim_form'][0].status_form)
    })

    if(claimFormStatus < 12){
      setDataTabOpen(
        <div className="col-md-12">
          <div className="border rounded padding-10" style={{minHeight: "300px"}}>
          <div className="emTitle_co p-2">
            <h2 className="size-16 weight-500 color-secondary mb-1">Payment Process</h2>
            <p className="size-12 color-text m-0">
              Payment will be process by SPK cover amount.
            </p>
          </div>
            <span className="d-block w-100 p-1 text-center text-muted">Payment Claim is not avilable for this <b>Status</b></span>
          </div>
        </div>
      )
    }else if(claimFormStatus == 13){
      setDataTabOpen(
        <div className="col-md-12">
          <div className="border rounded padding-10" style={{minHeight: "300px"}}>
          <div className="emTitle_co p-2">
            <h2 className="size-16 weight-500 color-secondary mb-1">Payment Process</h2>
            <p className="size-12 color-text m-0">
              Payment will be process by SPK cover amount.
            </p>
          </div>
            <span className="d-block w-100 p-1 text-center text-muted">Payment is process</span>
          </div>
        </div>
      )
    }else{
      paymentClaim(claimFormId).then((res) => {
        setDataTabOpen(
          <div className="col-md-12">
            <div className="border rounded padding-10" style={{minHeight: "300px"}}>
              <div className="emTitle_co p-2">
                <h2 className="size-16 weight-500 color-secondary mb-1">Payment Claim</h2>
                <p className="size-12 color-text m-0">
                  Payment will be process to your account number
                </p>
              </div>
              <div className="emPage___profile accordion bg-white" id="accordionExample">
                <div className="emBody__navLink with__border">
                  <section className="padding-10 pt-0 bg-white">
                    <div className="">
                      <div className="margin-b-0">
                        <small>Bank Name</small><br/>
                        <label>{res.data[0]['claim_form'].bank_name}</label>
                      </div>
                      <div className="margin-b-0">
                        <small>Account Name</small><br/>
                        <label>{res.data[0]['claim_form'].on_behalf_of}</label>
                      </div>
                      <div className="margin-b-0">
                        <small>Account Number</small><br/>
                        <label>{res.data[0]['claim_form'].account_number}</label>
                      </div>
                      <div className="margin-b-0">
                        <small>Status Payment</small><br/>
                        <label>{res.data[0]['claim_form'].status_user}</label>
                      </div>
                      <div className="margin-b-0">
                        <small>Payment Date</small><br/>
                        <label>{res.data[0]['claim_form'].payment_member_date == null ? '-' : res.data[0]['claim_form'].payment_member_date}</label>
                      </div>
                      <div className="margin-b-0">
                        <small>Payment Receipt</small><br/>
                        <label>{res.data[0]['claim_form'].payment_member_upload == null ? '-' : res.data[0]['claim_form'].payment_member_upload}</label>
                      </div>
                    </div>
                    {/* <div className="row mt-3">
                      <div className="col-12">
                        <button className="btn btn-sm btn-primary btn-block mb-2">View SPK</button>
                      </div>
                      <div className="col-12">
                        {res.data[0]['spk'].response === '1' ? (
                          <button className="btn btn-sm btn-primary btn-block mb-2" disabled='true'>Approve</button>
                        ) : (
                          <button className="btn btn-sm btn-primary btn-block mb-2" data-toggle="modal" data-target="#mdl_spk_approve">Approve</button>
                        )}
                      </div>
                      <div className="col-12">
                        <button className="btn btn-sm btn-warning btn-block" data-toggle="modal" data-target="#mdl_spk_appeal" onClick={event => formAppeal()}>Appeal</button>
                      </div>
                    </div> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
  }

  useEffect(() => {
    getDetailClaim()
  },[])

  useEffect(() => {
    handleTabOpen()
  },[tabOpen, dataDocumentClaim, dataAppeal])

  return (
    <div>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 10%)'}}>
        <div className="spinner_flash" />
      </section>
      <section className="padding-t-50 components_page">
        <div className="padding-l-20 padding-t-20 padding-b-10 padding-r-20">
          <h3 className="size-16 weight-600 color-secondary mb-1">General Information</h3>
        </div>
        <section className="padding-20 bg-white">
          <div className="">
            <div className="margin-b-0">
              <small>Claim Number</small><br/>
              <label>{dataDetailClaim.length === 0 ? '' : dataDetailClaim.data[0]['detail_claim'][0].claim_number}</label>
            </div>
            <div className="margin-b-0">
              <small>Status</small><br/>
              <label>{dataDetailClaim.length === 0 ? '' : dataDetailClaim.data[0]['detail_claim'][0].claim_status}</label>
            </div>
            <div className="margin-b-0">
              <small>Claimant name</small><br/>
              <label>{dataDetailClaim.length === 0 ? '' : dataDetailClaim.data[0]['detail_claim'][0].claimant_name}</label>
            </div>
            <div className="margin-b-0">
              <small>Claim Type</small><br/>
              <label>{dataDetailClaim.length === 0 ? '' : dataDetailClaim.data[0]['detail_claim'][0].claim_type} - {dataDetailClaim.length === 0 ? '' : (dataDetailClaim.data[0]['detail_claim'][0].claim_cover === 1 ? 'Individu' : 'Group')}</label>
            </div>
            <div className="margin-b-0">
              <small>Client</small><br/>
              <label>{dataDetailClaim.length === 0 ? '' : dataDetailClaim.data[0]['detail_claim'][0].client_name}</label>
            </div>
            <div className="margin-b-0">
              <small>Insurance Number</small><br/>
              <label>{dataDetailClaim.length === 0 ? '' : dataDetailClaim.data[0]['detail_claim'][0].policy_number}</label>
            </div>
          </div>
        </section>
        <div className="padding-l-20 padding-t-20 padding-b-10 padding-r-20">
          <h3 className="size-16 weight-600 color-secondary mb-1">Insured Claim</h3>
          <div className="dropdown default margin-t-10 margin-b-">
            <button className="btn btn-sm btn-danger btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {claimFormName}
            </button>
            <div className="dropdown-menu w-100 text-center" aria-labelledby="dropdownMenuButton">
              {
                dataDetailClaim.length === 0 ? '' : dataDetailClaim.data[1]['formlist'].map((formList, formKey) => {
                                                      return (
                                                        <span key={formKey} className="dropdown-item" onClick={event => getClaimFormDocument(formList.id_claimform)}>{formList.insured_name}</span>
                                                      );
                                                    })
              }
            </div>
          </div>
        </div>
        <div className="row padding-20 padding-b-10 bg-white">
          <div className="col-md-12">
            <button type="button" className={"btn btn-sm margin-r-5 "+(tabOpen === 'tabDocument' ? 'btn-primary' : 'btn-outline-primary')} onClick={event => setTabOpen('tabDocument')}>Upload</button>
            <button type="button" className={"btn btn-sm margin-r-5 "+(tabOpen === 'tabSpk' ? 'btn-primary' : 'btn-outline-primary')} onClick={event => setTabOpen('tabSpk')}>SPK</button>
            <button type="button" className={"btn btn-sm margin-r-5 "+(tabOpen === 'tabHardcopy' ? 'btn-primary' : 'btn-outline-primary')} onClick={event => setTabOpen('tabHardcopy')}>Hardcopy Claim</button>
            <button type="button" className={"btn btn-sm margin-r-5 "+(tabOpen === 'tabPayment' ? 'btn-primary' : 'btn-outline-primary')} onClick={event => setTabOpen('tabPayment')}>Payment</button>
          </div>
        </div>
        <div className="row padding-20 padding-t-0 bg-white">
          {dataTabOpen}
        </div>
      </section>
    </div>
  )
}
export default Main