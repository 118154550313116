import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";

function About(){
  const navigate = useNavigate()
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("none")

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  useEffect(() => {
    checkUserToken()
  },[]);

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content" className="">
        {/* Start main_haeder */}
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Back</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              About
            </span>
          </div>
        </header>
        {/* End.main_haeder */}
        {/* Start emPageAbout */}
        <section className="emPageAbout padding-t-60">
          <div className="emBox___logo bg-snow">
            <div className="brand mb-0">
              {/* <img src="assets/img/logo-b.svg" alt="" /> */}
                <h4 className='text-info'>TRIPINS GLOBAL</h4>
            </div>
            <p className="color-text size-12 m-0">Version 1.0.0</p>
          </div>
          <div className="content_text bg-white emBlock__border margin-b-0">
            <h3 className="size-20 weight-500 color-secondary mb-1">What we offer</h3>
            <p>
            Tripins it self stand for Travelling, Itinerary and Secure means
            that the traveller can built their
            itinerary inside the aps, and securing their trip using
            travel insurance which boost by auto claim and jet
            claim both reimbursement and cashless
            </p>
          </div>
          {/* <div className="content_text bg-white emBlock__border py-0">
            <div className="em_group full__border">
              <a href="#" className="itemListClassic">
                <div className="txt">
                  <h3> What's New ?</h3>
                </div>
                <div className="sideRight">
                  <span className="size-12 color-text d-block margin-r-10">2.0</span>
                  <i className="tio-chevron_right" />
                </div>
              </a>
              <a href="https://themeforest.net/user/orinostu" target="_blank" className="itemListClassic">
                <div className="txt">
                  <h3>Team Profile</h3>
                </div>
                <div className="sideRight">
                  <i className="tio-chevron_right" />
                </div>
              </a>
              <a href="https://themeforest.net/user/orinostu/portfolio" target="_blank" className="itemListClassic">
                <div className="txt">
                  <h3>Our Projects</h3>
                </div>
                <div className="sideRight">
                  <i className="tio-chevron_right" />
                </div>
              </a>
              <a href="page-contact.html" className="itemListClassic">
                <div className="txt">
                  <h3>Have a Question?</h3>
                </div>
                <div className="sideRight">
                  <i className="tio-chevron_right" />
                </div>
              </a>
            </div>
          </div> */}
        </section>
        <div className="w-100 bg-snow" style={{'height':'30px'}}></div>
        {/* End. emPageAbout */}
        {/* Start emSimple_main_footer */}
        <section className="emSimple_main_footer margin-t-0 border-t border-t-solid border-snow bg-white d-flex justify-content-center text-center padding-20">
          <div className="padding-t-10 padding-b-10">
            <a href="index.html" className="brand_sm margin-b-20 d-block">
              <img src="assets/img/logo-tripins.png" alt="" style={{'width':'35%', 'height':'auto'}} />
            </a>
            <h3 className="size-13 weight-400 color-secondary margin-b-10">
              Copyright © PT. OWLLAA 2022. All Rights Reserved.
            </h3>
            <p className="size-12 color-text margin-b-20">
            Contact us to discuss anything you’d like, or
            simply drop a word to say hi. We love to have a
            virtual coffees and always welcome the
            opportuinity
            </p>
            <div className="itemNetworks mt-2 emBlock__border">
              <a href="#" className="btn facebook">
                <img src="assets/img/icon/facebook.svg" alt="" />
              </a>
              <a href="#" className="btn instagram">
                <img src="assets/img/icon/instagram.svg" alt="" />
              </a>
              <a href="#" className="btn youtube">
                <img src="assets/img/icon/youtube.svg" alt="" />
              </a>
              <a href="#" className="btn twitter">
                <i className="ri-twitter-fill color-twitter" />
              </a>
              <button type="button" className="btn share" data-toggle="modal" data-target="#mdllButtons_share">
                <i className="ri-share-forward-box-line color-green" />
              </button>
            </div>
            <div className="link_privacy env-pb">
              <a href="#" className="btn">Privacy Policy</a>
              <a href="#" className="btn">Terms of Uses</a>
            </div>
          </div>
        </section>
        {/* End. emSimple_main_footer */}
      </div>
      {/* <Footer/> */}
    </div>
  );
}
export default About;