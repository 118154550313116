import React, {useEffect, useRef, useState} from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import {checkPrefix, submitClaim} from '../../Api/Claim';


function FormRegister(){
  const navigate = useNavigate()
  const location = useLocation()
  const btnGenerate= useRef('d-block')
  const btnGenerateLoading = useRef('d-none')
  const [btnGenerateView, setBtnGenerateView] = useState([])

  const policyNumber = useRef(location.state.polNumber)
  const clientId = useRef(location.state.clientId)
  const claimType = useRef(location.state.claimType)
  const claimCover = useRef(location.state.claimReq)
  const airPlane = useRef('')
  const flightNumber = useRef('')
  const departureDate = useRef('')
  const claimantName = useRef('')
  const claimantDob = useRef('')
  const claimantGender = useRef('')
  const claimantAddress = useRef('')
  const claimantTelephoneNo = useRef('')
  const claimantMobilePhone = useRef('')
  const claimantEmail = useRef('')
  const claimantOfficePhone = useRef('')
  const userId = useRef(localStorage.getItem('user-id'))

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    }
  }

  const viewBtnSubmit = () => {
    setBtnGenerateView(
      <div>
        <div className={btnGenerate.current}>
          <div className="buttons__footer text-center" style={{'width': '100%'}}>
            <button type="submit" className="btn bg-primary rounded-pill btn__default">
              <span className="color-white">Generate Form</span>
              <div className="icon rounded-circle">
                <i className="tio-chevron_right" />
              </div>
            </button>
          </div>
        </div>
        <div className={btnGenerateLoading.current}>
          <div className="buttons__footer text-center" style={{'width': '100%'}}>
            <button type="submit" className="btn bg-primary rounded-pill btn__default" disabled>
              <span className="spinner-border spinner-border-sm mr-2 color-white" role="status" aria-hidden="true" />
              <span className="color-white">Generate Form...</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const formHandler = async (e) => {
    e.preventDefault()
    btnGenerate.current = 'd-none'
    btnGenerateLoading.current = 'd-block'
    viewBtnSubmit()
    const formData = new FormData();
    formData.append('client', clientId.current);
    formData.append('claim_type', claimType.current);
    formData.append('policy_number', policyNumber.current);
    formData.append('claimant_name', claimantName.current);
    formData.append('claimant_dob', claimantDob.current);
    formData.append('claimant_gender', claimantGender.current);
    formData.append('claimant_address', claimantAddress.current);
    formData.append('claimant_telephone_no', claimantTelephoneNo.current);
    formData.append('claimant_mobile_phone', claimantMobilePhone.current);
    formData.append('claimant_email', claimantEmail.current);
    formData.append('claimant_office_phone', claimantOfficePhone.current);
    formData.append('claim_cover', claimCover.current);
    formData.append('created_by', userId.current);

    setTimeout(function() { 
      submitClaim(formData).then((res) => {
        btnGenerate.current = 'd-block'
        btnGenerateLoading.current = 'd-none'
        viewBtnSubmit()
        navigate('/claim-form-detail', { state: { 
          client : clientId.current, 
          claimId : res.data[0].claim_id, 
          claimNumber : res.data[0].claim_number, 
          claimantName : claimantName.current, 
          claimantDob : claimantDob.current, 
          claimantGender : claimantGender.current, 
          claimantAddress : claimantAddress.current, 
          claimantTelephoneNo : claimantTelephoneNo.current, 
          claimantMobilePhone : claimantMobilePhone.current, 
          claimantEmail : claimantEmail.current, 
          claimantOfficePhone : claimantOfficePhone.current, 
          airPlane : airPlane.current, 
          flightNumber : flightNumber.current, 
          departureDate : departureDate.current
        } })
      })
    }, 1000) 
  }

  useEffect(() => {
    checkUserToken()
    viewBtnSubmit()
  },[]);

  return (
    <div>
      <div id="wrapper">
        <div id="content" className="">
          <div>
            {/* Start main_haeder */}
            <header className="main_haeder header-sticky multi_item">
              <div className="em_side_right">
                <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
                  <i className="tio-chevron_left size-24 color-text" />
                  <span className="color-text size-14">Back</span>
                </a>
              </div>
              <div className="title_page">
                <span className="page_name">
                  Claim Form
                </span>
              </div>
            </header>
            {/* End.main_haeder */}
            {/* Start em__signTypeOne */}
            <section className="em__signTypeOne typeTwo np__account padding-t-70">
              <div className="em_titleSign">
                <h1>Let's Get Started!</h1>
                <p>Please enter all data in the form below</p>
              </div>
              <form onSubmit={formHandler}>
                <div className="em__body pb-0">
                  <section className="bg-white">
                    <div className="">
                      <div className="mb-2">
                        <label className="text-secondary">Policy Number</label>
                        <label className="float-right">{policyNumber.current}</label>
                      </div>
                      <div className="mb-2">
                        <label className="text-secondary">Claim Type</label>
                        <label className="float-right">{claimType.current === 1 ? 'Cashless' : 'Reimbursement'}</label>
                      </div>
                      <div className="mb-2">
                        <label className="text-secondary">Claim Request</label>
                        <label className="float-right">{claimCover.current === 2 ? 'Individu' : 'Group'}</label>
                      </div>
                    </div>
                  </section>
                  <div className="padding-t-20">
                    <div className="margin-b-30">
                      <div className="form-group input-lined lined__iconed">
                        <div className="input_group">
                          <select className="form-control custom-select size-14" defaultValue='0' onChange={(e) => airPlane.current = (e.target.value)} required>
                            <option value='0'>Please Select</option>
                            <option>Batik Air</option>
                            <option>Citilink</option>
                            <option>Garuda Indonesia</option>
                            <option>Indonesia AirAsia</option>
                            <option>Lion Air</option>
                            <option>Nam Air</option>
                            <option>Pelita Air</option>
                            <option>Sriwijaya Air</option>
                            <option>TransNusa</option>
                            <option>Wings Air</option>
                          </select>
                          <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M21.949 10.112C22.1634 10.9122 21.6886 11.7347 20.8884 11.9491L5.2218 16.1469C4.77856 16.2657 4.31138 16.0676 4.08866 15.6664L1.46582 10.9417L2.91471 10.5535L5.3825 12.998L10.4778 11.6328L5.96728 4.55914L7.89913 4.0415L14.8505 10.4611L20.1119 9.05131C20.9121 8.8369 21.7346 9.31177 21.949 10.112ZM4.00013 19.0002H20.0001V21.0002H4.00013V19.0002Z" fill="rgba(173,184,194,1)"></path></svg>
                          </div>
                        </div>
                        <label>Airplane Name <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="margin-b-30">
                      <div className="form-group input-lined lined__iconed">
                        <div className="input_group">
                          <input type="text" className="form-control" placeholder="Input your flight number" onChange={(e) => flightNumber.current = (e.target.value)} required />
                          <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM7 6H11V10H7V6ZM7 12H17V14H7V12ZM7 16H17V18H7V16ZM13 7H17V9H13V7Z" fill="rgba(173,184,194,1)"></path></svg>
                          </div>
                        </div>
                        <label htmlFor="email">Flight Number <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="form-group input-lined lined__iconed">
                      <div className="input_group">
                        <input type="date" id="pass" className="form-control" placeholder="Input departure date" onChange={(e) => departureDate.current = (e.target.value)} required />
                        <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z" fill="rgba(173,184,194,1)"></path></svg>
                        </div>
                      </div>
                      <label htmlFor="pass">Departure Date <span className="text-danger">*</span></label>
                    </div>
                  </div>
                </div>
                <div className="padding-20 bg-snow mb-3">
                  <h2 className="size-20 weight-600 color-secondary mb-1">Claimant Information</h2>
                  <p className="size-12 color-text m-0">Submit your data as claimant</p>
                </div>
                <div className="em__body pb-0">
                  <div className="padding-t-20">
                    <div className="margin-b-30">
                      <div className="form-group input-lined">
                        <input type="text" className="form-control" placeholder="Type your name" onChange={(e) => claimantName.current = (e.target.value)} required />
                        <label>Fullname <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="margin-b-30">
                      <div className="form-group input-lined">
                        <input type="date" className="form-control" onChange={(e) => claimantDob.current = (e.target.value)} required />
                        <label>Date of Birth <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="margin-b-30">
                      <div className="form-group input-lined">
                        <select className="form-control custom-select size-14" defaultValue="0" onChange={(e) => claimantGender.current = (e.target.value)} required>
                          <option value="0" disabled>Please Select</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                        </select>
                        <label>Gender <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="margin-b-30">
                      <div className="form-group">
                        <label>Address <span className="text-danger">*</span></label>
                        <textarea className="form-control" rows={2} placeholder="Type your address..." onChange={(e) => claimantAddress.current = (e.target.value)} required />
                      </div>
                    </div>
                    <div className="margin-b-30">
                      <div className="form-group input-lined">
                        <input type="text" className="form-control" placeholder="Telephone number" onChange={(e) => claimantTelephoneNo.current = (e.target.value)} />
                        <label>Telephone Number</label>
                      </div>
                    </div>
                    <div className="margin-b-30">
                      <div className="form-group input-lined">
                        <input type="text" className="form-control" placeholder="Phone number" onChange={(e) => claimantMobilePhone.current = (e.target.value)} required />
                        <label>Mobile Phone <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="margin-b-30">
                      <div className="form-group input-lined">
                        <input type="email" className="form-control" placeholder="Type your email address" onChange={(e) => claimantEmail.current = (e.target.value)} required />
                        <label>Email Address <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="margin-b-30">
                      <div className="form-group input-lined">
                        <input type="text" className="form-control" placeholder="Office number" onChange={(e) => claimantOfficePhone.current = (e.target.value)} />
                        <label>Office Phone Number</label>
                      </div>
                    </div>
                  </div>
                </div>
                {btnGenerateView}
              </form>
            </section>
            {/* End. em__signTypeOne */}
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    </div>
  );
}
export default FormRegister