import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL
const token = localStorage.getItem('token')
const config = {
  // headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" }
  headers: { 
              'Authorization': `Bearer ${token}`,
              // 'Content-Type': 'application/json', 
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "true",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "content-type",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH",
            }
}

const configNoAuth = {
  headers: { 
              // 'Content-Type': 'application/x-www-form-urlencoded', 
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "false",
              "Access-Control-Max-Age": "1800",
              "Access-Control-Allow-Headers": "Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization",
              "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTION",
            }
}

export const login = async (formData) => {
  const reqLogin = await axios.post(`${baseUrl}/login`, formData, configNoAuth)
  if(reqLogin.data.response === 'SUCCESS'){
    localStorage.setItem('user-id', reqLogin.data.data.id_users);
    localStorage.setItem('token', reqLogin.data.token);
    localStorage.setItem('fullname', reqLogin.data.data.fullname);
    localStorage.setItem('email', reqLogin.data.data.email);
  }
  return reqLogin.data
}

export const personalData = async (userId) => {
  const bodyParameters = {
    id_users: userId
  };

  const req = await axios.post(`${baseUrl}/personal-details`, bodyParameters, config)
  return req.data
}

export const updateProfile = async (formData) => {
  const reqUpdate = await axios.post(`${baseUrl}/update-user`, formData, config)
  return reqUpdate.data
}

export const updatePassword = async (formData) => {
  const reqUpdate = await axios.post(`${baseUrl}/update-password`, formData, config)
  return reqUpdate.data
}

export const logout = async () => {
  const token = localStorage.getItem('token')
  const config = {
    headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json' }
  }

  const bodyParameters = {
    key: "value"
  };
  
  const reqLogout = await axios.post(`${baseUrl}/logout`, bodyParameters, config)
  localStorage.clear()
  return true;
}

export const register = async (formData) => {
  const reqRegis = await axios.post(`${baseUrl}/register`, formData, configNoAuth)
  return reqRegis.data
}

export const verification = async (formData) => {
  const reqRegis = await axios.post(`${baseUrl}/verify`, formData, configNoAuth)
  return reqRegis.data
}