import React from 'react';
// import React, {Component} from 'react';
import { useNavigate } from 'react-router-dom';
import {logout} from '../Api/Auth';

// class SidePanel extends Component{
function SidePanel(){
  const navigate = useNavigate()
  const userFullname = localStorage.getItem('fullname')
  const userEmail = localStorage.getItem('email')

  const logOutHandler = async () => {
    logout().then((response) => {
      if(response){
        navigate('/login')
      }
    })
  }

  // render(){
    return (
      <div className="modal sidebarMenu -left fade" id="mdllSidebarMenu-background" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-block">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="tio-clear" />
              </button>
              <div className="em_profile_user">
                <div className="media">
                  <a href="page-profile.html">
                    {/* You can use an image */}
                    {/* <img className="_imgUser" src="assets/img/person.png" alt=""> */}
                    <div className="letter bg-yellow">
                      <span>c</span>
                    </div>
                  </a>
                  <div className="media-body">
                    <div className="txt">
                      <h3>{userFullname}</h3>
                      <p>{userEmail}</p>
                      <button className="btn bg-primary rounded-10 text-white mt-2" onClick={logOutHandler} data-dismiss="modal" aria-label="Close">Sign out</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="np_balanceDefault bg-snow">
              </div>
              {/* <div className="np_balanceDefault emBlock__border with_bg">
                <div className="txt">
                  <span className="title_sm">My Balance</span>
                  <h3>95.00 <span>USD</span></h3>
                  <p>Exp on Jan 15, 2021</p>
                </div>
                <div className="npRight">
                  <a href="page-add-balance.html" className="btn">
                    <svg id="Iconly_Curved_Plus" data-name="Iconly/Curved/Plus" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                      <g id="Plus" transform="translate(1.917 1.917)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M.526,0V5.957" transform="translate(7.588 5.136)" fill="none" stroke="#0e132d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_2" data-name="Stroke 2" d="M5.963.526H0" transform="translate(5.132 7.588)" fill="none" stroke="#0e132d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,8.114C0,2.029,2.029,0,8.114,0s8.114,2.029,8.114,8.114S14.2,16.228,8.114,16.228,0,14.2,0,8.114Z" transform="translate(0)" fill="none" stroke="#0e132d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </a>
                </div>
              </div> */}
            </div>
            <div className="modal-body">
              <ul className="nav flex-column -active-links">
                <li className="nav-item">
                  <a className="nav-link" href="/profile">
                    <div>
                      <div className="icon_current">
                        <svg id="Iconly_Curved_Discovery" data-name="Iconly/Curved/Discovery" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                          <g id="Discovery" transform="translate(2.292 2.292)">
                            <path id="Stroke_1" data-name="Stroke 1" d="M0,7.708c0,5.781,1.927,7.708,7.708,7.708s7.708-1.927,7.708-7.708S13.489,0,7.708,0,0,1.927,0,7.708Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_3" data-name="Stroke 3" d="M0,5.5,1.312,1.312,5.5,0,4.192,4.191Z" transform="translate(4.957 4.957)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          </g>
                        </svg>
                      </div>
                      <span className="title_link">Profile</span>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/claim-history">
                    <div >
                      <div className="icon_current">
                        <svg id="Iconly_Curved_Home" data-name="Iconly/Curved/Home" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                          <g id="Home" transform="translate(2 1.667)">
                            <path id="Stroke_1" data-name="Stroke 1" d="M0,.5H4.846" transform="translate(5.566 11.28)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_2" data-name="Stroke 2" d="M0,9.761C0,5.068.512,5.4,3.266,2.842,4.471,1.872,6.346,0,7.965,0S11.5,1.862,12.712,2.842c2.754,2.554,3.265,2.227,3.265,6.919,0,6.906-1.633,6.906-7.988,6.906S0,16.667,0,9.761Z" transform="translate(0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          </g>
                        </svg>
                      </div>
                      <span className="title_link">Your Claim</span>
                    </div>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="app-components.html">
                    <div >
                      <div className="icon_current">
                        <svg id="Iconly_Curved_More_Circle" data-name="Iconly/Curved/More Circle" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                          <g id="More_Circle" data-name="More Circle" transform="translate(2.292 2.292)">
                            <path id="Stroke_4" data-name="Stroke 4" d="M0,7.708C0,1.927,1.927,0,7.708,0s7.708,1.927,7.708,7.708-1.927,7.708-7.708,7.708S0,13.489,0,7.708Z" transform="translate(0 0)" fill="none" stroke="#556fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_11" data-name="Stroke 11" d="M.5.5H.5" transform="translate(9.883 8.792)" fill="none" stroke="#556fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                            <path id="Stroke_13" data-name="Stroke 13" d="M.5.5H.5" transform="translate(7.383 5.458)" fill="none" stroke="#556fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                            <path id="Stroke_15" data-name="Stroke 15" d="M.5.5H.5" transform="translate(4.876 8.792)" fill="none" stroke="#556fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} />
                          </g>
                        </svg>
                      </div>
                      <span className="title_link">Components</span>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="app-pages.html">
                    <div >
                      <div className="icon_current">
                        <svg id="Iconly_Curved_Document" data-name="Iconly/Curved/Document" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                          <g id="Document" transform="translate(3.008 2.292)">
                            <path id="Stroke_1" data-name="Stroke 1" d="M6.017.5H0" transform="translate(3.971 10.289)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_2" data-name="Stroke 2" d="M6.017.5H0" transform="translate(3.971 7.155)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_3" data-name="Stroke 3" d="M2.3.5H0" transform="translate(3.972 4.023)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_4" data-name="Stroke 4" d="M0,7.708c0,5.781,1.748,7.708,6.992,7.708s6.992-1.927,6.992-7.708S12.238,0,6.992,0,0,1.927,0,7.708Z" transform="translate(0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          </g>
                        </svg>
                      </div>
                      <span className="title_link">Pages</span>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="page-products-fullwidth.html">
                    <div >
                      <div className="icon_current">
                        <svg id="Iconly_Curved_Bag" data-name="Iconly/Curved/Bag" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                          <g id="Bag" transform="translate(2.458 2.125)">
                            <path id="Stroke_1" data-name="Stroke 1" d="M7.216,3.6A3.608,3.608,0,0,0,0,3.584V3.6" transform="translate(3.962)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_3" data-name="Stroke 3" d="M.515.5H.477" transform="translate(9.504 6.853)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_5" data-name="Stroke 5" d="M.515.5H.477" transform="translate(4.644 6.853)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_7" data-name="Stroke 7" d="M7.57,12.445c-5.423,0-6.047-1.708-7.265-6.222S1.534,0,7.57,0s8.487,1.694,7.265,6.222S12.994,12.445,7.57,12.445Z" transform="translate(0 3.338)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          </g>
                        </svg>
                      </div>
                      <span className="title_link">Shop</span>
                    </div>
                    <span className="bg-red rounded-7 px-1 color-white min-w-25 px-1 h-28 d-flex align-items-center justify-content-center">3</span>
                  </a>
                </li> */}
                <label className="title__label">other</label>
                <li className="nav-item">
                  <a className="nav-link" href="page-profile.html">
                    <div >
                      <div className="icon_current">
                        <svg id="Iconly_Curved_Setting" data-name="Iconly/Curved/Setting" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                          <g id="Setting" transform="translate(2.917 2.083)">
                            <path id="Stroke_1" data-name="Stroke 1" d="M2.083,0A2.083,2.083,0,1,1,0,2.083,2.083,2.083,0,0,1,2.083,0Z" transform="translate(5 5.833)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_3" data-name="Stroke 3" d="M13.89,3.959h0a2.053,2.053,0,0,0-2.816-.76A1.286,1.286,0,0,1,9.145,2.077,2.07,2.07,0,0,0,7.083,0h0A2.07,2.07,0,0,0,5.021,2.077,1.286,1.286,0,0,1,3.093,3.2a2.054,2.054,0,0,0-2.817.76A2.085,2.085,0,0,0,1.031,6.8a1.3,1.3,0,0,1,0,2.243,2.085,2.085,0,0,0-.755,2.837,2.054,2.054,0,0,0,2.816.761h0a1.285,1.285,0,0,1,1.928,1.121h0a2.07,2.07,0,0,0,2.062,2.077h0a2.07,2.07,0,0,0,2.062-2.077h0a1.286,1.286,0,0,1,1.929-1.121,2.054,2.054,0,0,0,2.816-.761,2.085,2.085,0,0,0-.754-2.837h0a1.3,1.3,0,0,1,0-2.243A2.085,2.085,0,0,0,13.89,3.959Z" transform="translate(0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          </g>
                        </svg>
                      </div>
                      <span className="title_link">Settings</span>
                    </div>
                    {/* <div className="em_pulp">
                      <span className="doted_item" />
                    </div> */}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/support">
                    <div >
                      <div className="icon_current">
                        <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                          <g id="Message" transform="translate(2.043 2.377)">
                            <path id="Stroke_1" data-name="Stroke 1" d="M9.292,0S6.617,3.211,4.661,3.211,0,0,0,0" transform="translate(3.285 5.139)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_3" data-name="Stroke 3" d="M0,7.6C0,1.9,1.984,0,7.937,0s7.937,1.9,7.937,7.6-1.984,7.6-7.937,7.6S0,13.295,0,7.6Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          </g>
                        </svg>
                      </div>
                      <span className="title_link">Support</span>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    <div >
                      <div className="icon_current">
                        <svg id="Iconly_Curved_Info_Square" data-name="Iconly/Curved/Info Square" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
                          <g id="Info_Square" data-name="Info Square" transform="translate(2.292 2.292)">
                            <path id="Stroke_1" data-name="Stroke 1" d="M0,7.708C0,1.927,1.927,0,7.708,0s7.708,1.927,7.708,7.708-1.927,7.708-7.708,7.708S0,13.489,0,7.708Z" transform="translate(15.417 15.417) rotate(180)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_3" data-name="Stroke 3" d="M0,0V3.246" transform="translate(7.708 10.954) rotate(180)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                            <path id="Stroke_15" data-name="Stroke 15" d="M0,0H.007" transform="translate(7.712 4.792) rotate(180)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          </g>
                        </svg>
                      </div>
                      <span className="title_link">About</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="modal-footer pb-3">
              <div className="em_darkMode_menu">
                <label className="text" htmlFor="switchDarkMode">
                  <h3>Dark Mode</h3>
                  <p>Browsing in night mode</p>
                </label>
                <label className="switch_toggle toggle_lg" htmlFor="switchDarkMode">
                  <input type="checkbox" className="switchDarkMode" id="switchDarkMode" />
                  <span className="handle" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  // }
}
export default SidePanel;