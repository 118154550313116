import axios from "axios";

const baseUrl = process.env.REACT_APP_BASEURL
const token = localStorage.getItem('token')
// res.setHeader("Access-Control-Allow-Origin", "*")
// res.setHeader("Access-Control-Allow-Credentials", "true");
// res.setHeader("Access-Control-Max-Age", "1800");
// res.setHeader("Access-Control-Allow-Headers", "content-type");
// res.setHeader( "Access-Control-Allow-Methods", "PUT, POST, GET, DELETE, PATCH, OPTIONS" ); 
const config = {
  headers: { 
    // 'Content-Type': 'application/json', 
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Max-Age": "1800",
    "Access-Control-Allow-Headers": "content-type",
    "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH",
    // 'Origin': 'https://claim.tripinsglobal.com',
    'Authorization': `Bearer ${token}`
  }
}

export const claimList = async (userId) => {
  const bodyParameters = {
    user_id: userId
  };

  const req = await axios.post(`${baseUrl}/get-claim`, bodyParameters, config)
  return req.data
}

export const claimHistory = async (userId) => {
  const bodyParameters = {
    user_id: userId
  };

  const req = await axios.post(`${baseUrl}/get-claim-history`, bodyParameters, config)
  return req.data
}

export const checkPrefix = async (policyNumber) => {
  const bodyParameters = {
    policy_number: policyNumber
  }

  const req = await axios.post(`${baseUrl}/check-prefix`, bodyParameters, config)
  return req.data.data
}

export const submitClaim = async (formData) => {
  const req = await axios.post(`${baseUrl}/new-claim`, formData, config)
  return req.data
}

export const submitClaimForm = async (formData) => {
  const req = await axios.post(`${baseUrl}/new-claim-form`, formData, config)
  return req.data
}

export const registeredCompleted = async (claim) => {
  const bodyParameters = {
    claim: claim
  }

  const req = await axios.post(`${baseUrl}/registered-completed`, bodyParameters, config)
  return req.data
}

export const clientPlan = async (client) => {
  const req = await axios.get(`${baseUrl}/client/plan/${client}`, config)
  return req.data
}

export const clientPlanDetail = async (plan) => {
  const req = await axios.get(`${baseUrl}/client/plan/detail/${plan}`, config)
  return req.data
}

export const detailClaim = async (uuid) => {
  const bodyParameters = {
    uuid: uuid
  };

  const req = await axios.post(`${baseUrl}/detail-claim`, bodyParameters, config)
  return req.data
}

export const detailClaimForm = async (id) => {
  const bodyParameters = {
    id_claimform: id
  };

  const req = await axios.post(`${baseUrl}/detail-claim-form`, bodyParameters, config)
  return req.data
}

export const uploadDocument = async (formData) => {
  const config = {
    headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data' }
  }
  const req = await axios.post(`${baseUrl}/claim/upload-document`, formData, config)
}

export const claimFormDocument = async (idClaimForm) => {
  const bodyParameters = {
    id_claimform: idClaimForm
  };

  const req = await axios.post(`${baseUrl}/claim-form-document`, bodyParameters, config)
  // console.log(req.data)
  return req.data
}

export const spkClaim = async (idClaimForm) => {
  const bodyParameters = {
    id_claimform: idClaimForm
  };

  const req = await axios.post(`${baseUrl}/spk-claim`, bodyParameters, config)
  return req.data
}

export const spkPdf = async (idClaimForm) => {
  const bodyParameters = {
    id_claimform: idClaimForm
  };

  const req = await axios.post(`${baseUrl}/spk-pdf`, bodyParameters, config)
  return req
}

export const appealClaim = async (idClaimForm) => {
  const bodyParameters = {
    id_claimform: idClaimForm
  }

  const req = await axios.post(`${baseUrl}/appeal-member`, bodyParameters, config)
  // console.log(req)
  return req.data
}

export const sendResponseSpk = async (formData) => {
  const reqLogin = await axios.post(`${baseUrl}/spk-response`, formData, config)
  
  return reqLogin.data
}

export const spkApprove = async (formData) => {
  const reqLogin = await axios.post(`${baseUrl}/spk-response`, formData, config)
  return reqLogin
}

export const documentHardcopy = async (idClaimForm) => {
  const bodyParameters = {
    id_claimform: idClaimForm
  };

  const req = await axios.post(`${baseUrl}/document-hardcopy`, bodyParameters, config)
  // console.log(req)
  return req.data
}

export const InputDocumentHardcopy = async (formData) => {
  const reqLogin = await axios.post(`${baseUrl}/input-document-hardcopy`, formData, config)
  
  return reqLogin.data
}

export const paymentClaim = async (id) => {
  const bodyParameters = {
    id_claimform: id
  };

  const req = await axios.post(`${baseUrl}/payment-claim`, bodyParameters, config)
  return req.data
}