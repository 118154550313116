import React, {useEffect} from 'react';
// import { useNavigate } from 'react-router-dom';

function SplashScreen(){
  // const navigate = useNavigate();

  useEffect(() => {
    setTimeout(function() { 
      // navigate('/intro')
      window.location.replace('/intro');
    }, 1500)
  })

  return (
    <div className="vh-100 d-flex align-items-center text-center">
      <div className="spalsh__screen mx-auto">
        <div className="logo" style={{marginTop : '45vh'}}>
          <img src="assets/img/logo-tripins.png" width={'auto'} height={50} alt="" />
        </div>
        <div className="env-pb d-flex justify-content-center">
          <p className="absolute bottom-0 mb-0 size-11 color-text padding-b-30">
            Copyright © Tripins Global 2022
          </p>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen;