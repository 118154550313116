import React, {useEffect, useRef, useState} from 'react';
import Footer from '../../Components/Footer';
import { productInsurance } from '../../Api/Master';

function Insurance(){
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const isLogin = useRef(0)
  const [productRes, setProductRes] = useState(null)

  // const [productRes, setProductRes] = useState([
  //   {
	// 		id_plan: 1,
	// 		plan_name: "Z.Travellin - Basic",
	// 		description: null,
	// 		image: "Z.Travellin-Basic-1669041289.jpg",
	// 		abbreviation_name: "Owlaa Global"
	// 	},
	// 	{
	// 		id_plan: 2,
	// 		plan_name: "Z.Travellin - Premiere",
	// 		description: null,
	// 		image: "Z.Travellin-Executive-1669041237.jpg",
	// 		abbreviation_name: "Owlaa Global"
	// 	},
	// 	{
	// 		id_plan: 3,
	// 		plan_name: "Z.Travellin - Executive",
	// 		description: null,
	// 		image: "Z.Travellin-Premiere1-1670585349.jpg",
	// 		abbreviation_name: "Owlaa Global"
	// 	},
	// 	{
	// 		id_plan: 4,
	// 		plan_name: "Z.Travellin - Gold",
	// 		description: null,
	// 		image: "Plan1-1670484332.png",
	// 		abbreviation_name: "Owlaa Global"
	// 	}
  // ])

  const checkUserToken = () => {
    const userToken = localStorage.getItem('token')
    if (!userToken || userToken === 'undefined') {
      isLogin.current = 0
    }else{
      isLogin.current = 1
    }
  }

  const getProductInsurance = async () => {
    productInsurance().then((res) => {
      setProductRes(res.data)
    })
  }

  useEffect(() => {
    checkUserToken()
    getProductInsurance()
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
  },[])

  return (
    <div id="wrapper">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content" className="">
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            <a className="rounded-circle d-flex align-items-center text-decoration-none" href="/home">
              <i className="tio-chevron_left size-24 color-text" />
              <span className="color-text size-14">Home</span>
            </a>
          </div>
          <div className="title_page">
            <span className="page_name">
              Travel Insurance
            </span>
          </div>
        </header>

        <section className="emCoureses__grid course__list">
          <div className="em_bodyCarousel padding-t-70">
            {
              productRes == null ? (
                <div className="text-center">
                  <span className="text-muted">Loading...</span>
                </div>
              ) : 
              productRes.map((product, Key) => {
                return (
                  <div className="em_itemCourse_grid list">
                    <a href="page-chapters-learning.html" className="card">
                      <div className="row no-gutters">
                        <div className="col-4">
                          <div className="cover_card">
                            <img src={'https://tripinsglobal.com/app/file/master-data/client/plan/'+productRes[Key].image} className="card-img-top" alt="img" />
                          </div>
                        </div>
                        <div className="col-8 my-auto">
                          <div className="card-body">
                            {/* <div className="head_card">
                              <span>- 5 h 30 min</span>
                              <span>45 lectures</span>
                            </div> */}
                            <h5 className="card-title">
                              {productRes[Key].plan_name}
                            </h5>
                            {/* <p className="card-text text-wrap">
                              {desc.substring(0, 100)+'...'}
                            </p> */}
                            <div className="card_footer">
                              <div className="card_user mt-1">
                                <span>{productRes[Key].abbreviation_name}</span>
                              </div>
                              <div className="size-14 weight-600 color-secondary">
                                <a href="#" className="size-12 weight-600 color-secondary">
                                  <span className='color-text'>Discover</span>
                                  <i className="tio-chevron_right" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </section>
      </div>
      {
        isLogin.current === 1 ? (<Footer/>) : ''
      }
    </div>
  )
}

export default Insurance;