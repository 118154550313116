import React, {useEffect, useState, useRef} from 'react'
import { useNavigate } from "react-router-dom"
import {claimHistory} from '../../Api/Claim'

function Main(props){
  const navigate = useNavigate()
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [claimRes, setClaimRes] = useState([])
  const [claim, setClaim] = useState([(
                                        <div className="alert alert-light text-center" role="alert">
                                          <div>Loading... </div>
                                        </div>
                                      )])


  const navigateClaimView = (uuid_claim) => {
    navigate('/claim-view', { state: uuid_claim })
  }
  const getClaim = async () => {
    const userId = localStorage.getItem('user-id')
    claimHistory(userId).then((res) => {
      if(res.data.length === 0){
        setClaim(
          <div className="alert alert-light text-center" role="alert">
            <div>Claim is not available. </div>
          </div>
        )
      }else{
        setClaimRes(res.data)
      }
    })
  }

  const claimList = () => {
    setClaim(
      claimRes.map((claim, Key) => {
        return (
          <div className="btn em__itemList_jobs">
            <div className="media align-items-center">
              <div className="media-body">
                <div className="txt">
                  <h3>{claimRes[Key].claim_number}</h3>
                  <p>{claimRes[Key].submit_date}</p>
                </div>
              </div>
            </div>
            <div className="sideRight">
              <button onClick={() => navigateClaimView(claimRes[Key].uuid)} className="btn btn_default bg-primary rounded-10 text-white px-4">View</button>
            </div>
          </div>
        )
      })
    )
  }

  useEffect(function() {
    claimList()
  },[claimRes])

  useEffect(() => {
    getClaim()
  },[])

  return (
    <div>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 10%)'}}>
        <div className="spinner_flash" />
      </section>
      <section className="padding-t-70 components_page" style={{height: "100vh"}}>
        {/* Start title */}
        <div className="emTitle_co padding-20">
          <h2 className="size-16 weight-500 color-secondary mb-1">Claim History</h2>
          <p className="size-12 color-text m-0">Show claims list</p>
        </div>
        {/* End. title */}
        <div className>
          <div className="emContent_listJobs padding-20 pt-0">
            {claim}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Main