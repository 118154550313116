import React, {useState, useEffect, useRef} from 'react';
// import { useNavigate } from 'react-router-dom';
import {login} from '../Api/Auth';

function AuthLogin(){
  // const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState("")
  
  const [loaderDisplayBg0, setLoaderDisplayBg0] = useState("none")
  const [loaderDisplay, setLoaderDisplay] = useState("flex")
  const inputRef = useRef(null);

  const loginHandler = async (e) => {
    setValidation("")
    setLoaderDisplayBg0('flex')
    e.preventDefault()
    setTimeout(function() { 
      const formData = new FormData();
      formData.append('email', email);
      formData.append('current_password', password);
      login(formData).then((res) => {
        if(res.response === 'SUCCESS'){
          // navigate('/home')
          window.location.replace('/home');
        }else{
          setValidation(res.message)
          inputRef.current.focus()
        }
        setLoaderDisplayBg0('none')
      })
    }, 1000)
  };

  useEffect(function() {
    setTimeout(function() { 
      setLoaderDisplay('none')
    }, 1000)
    inputRef.current.focus()
  },[])

  return (
    <div id="wrapper" className="vh-100">
      <section className="em_loading" id="loader" style={{display: loaderDisplay}}>
        <div className="spinner_flash"></div>
      </section>
      <section className="em_loading" id="loaderBg0" style={{display: loaderDisplayBg0, backgroundColor: 'rgb(41 46 52 / 0%)'}}>
        <div className="spinner_flash" />
      </section>
      <div id="content">
        {/* Start main_haeder */}
        <header className="main_haeder header-sticky multi_item">
          <div className="em_side_right">
            {/* <a className="rounded-circle d-flex align-items-center text-decoration-none" href="app-pages.html">
                      <i className="tio-chevron_left size-24 color-text"></i>
                      <span className="color-text size-14">Back</span>
                  </a> */}
            <span className="page_name h5">
              TRIPINS GLOBAL
            </span>
          </div>
          {/* <div className="title_page">
                  <span className="page_name">
                      TRIPINS GLOBAL
                  </span>
              </div> */}
          <div className="em_side_right">
            <a href="/forgot-password" className="link__forgot d-block size-14 color-text text-decoration-none hover:color-secondary transition-all">
              Forgot Password?
            </a>
          </div>
        </header>
        {/* End.main_haeder */}
        {/* Start em__signTypeOne */}
        <section className="em__signTypeOne typeTwo np__account padding-t-70">
          <div className="em_titleSign">
            <h1>Welcome Back!</h1>
            <p>Enter your credentials to continue</p>
            <div className="logSocial">
              <a href="/home" className="btn __google border-solid border-snow rounded-10">
                <div className="icon">
                  <svg id="search" xmlns="http://www.w3.org/2000/svg" width="19.469" height="19.469" viewBox="0 0 19.469 19.469">
                    <path id="Path_1675" data-name="Path 1675" d="M4.315,144.776l-.678,2.53-2.477.052a9.752,9.752,0,0,1-.072-9.09h0l2.205.4.966,2.192a5.81,5.81,0,0,0,.055,3.911Z" transform="translate(0 -133.01)" fill="#fbbb00" />
                    <path id="Path_1676" data-name="Path 1676" d="M270.978,208.176a9.731,9.731,0,0,1-3.47,9.41h0l-2.778-.142-.393-2.454a5.8,5.8,0,0,0,2.5-2.963h-5.205v-3.851h9.35Z" transform="translate(-251.679 -200.26)" fill="#518ef8" />
                    <path id="Path_1677" data-name="Path 1677" d="M45.177,314.969h0a9.738,9.738,0,0,1-14.669-2.978l3.155-2.582a5.79,5.79,0,0,0,8.343,2.964Z" transform="translate(-29.349 -297.643)" fill="#28b446" />
                    <path id="Path_1678" data-name="Path 1678" d="M43.484,2.241,40.33,4.823A5.789,5.789,0,0,0,31.8,7.854l-3.171-2.6h0a9.737,9.737,0,0,1,14.86-3.017Z" transform="translate(-27.536)" fill="#f14336" />
                  </svg>
                </div>
              </a>
            </div>
          </div>
          <div className="em__body">
            <form onSubmit={loginHandler} className="padding-t-10">
              <div className="margin-b-30">
                <div className="form-group input-lined lined__iconed">
                  <div className="input_group">
                    <input type="email" name="email" className="form-control" id="email" placeholder="example@mail.com" ref={inputRef} value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <div className="icon">
                      <svg id="Iconly_Curved_Message" data-name="Iconly/Curved/Message" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                        <g id="Message" transform="translate(2.248 2.614)">
                          <path id="Stroke_1" data-name="Stroke 1" d="M10.222,0S7.279,3.532,5.127,3.532,0,0,0,0" transform="translate(3.613 5.653)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M0,8.357C0,2.089,2.183,0,8.73,0s8.73,2.089,8.73,8.357-2.183,8.357-8.73,8.357S0,14.624,0,8.357Z" transform="translate(0 0)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <label htmlFor="email">Email Address</label>
                </div>
              </div>
              <div className="form-group input-lined lined__iconed" id="show_hide_password">
                <div className="input_group">
                  <input type="password" id="password" name="password" className="form-control" placeholder="enter your password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  <div className="icon">
                    <svg id="Iconly_Curved_Password" data-name="Iconly/Curved/Password" xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 22 22">
                      <g id="Password" transform="translate(2.521 2.521)">
                        <path id="Stroke_1" data-name="Stroke 1" d="M3.4,1.7A1.7,1.7,0,1,1,1.7,0h0A1.7,1.7,0,0,1,3.4,1.7Z" transform="translate(3.882 6.781)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_3" data-name="Stroke 3" d="M0,0H5.792V1.7" transform="translate(7.28 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_5" data-name="Stroke 5" d="M.5,1.7V0" transform="translate(9.979 8.479)" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                        <path id="Stroke_7" data-name="Stroke 7" d="M0,8.479C0,2.12,2.12,0,8.479,0s8.479,2.12,8.479,8.479-2.12,8.479-8.479,8.479S0,14.838,0,8.479Z" fill="none" stroke="#9498ac" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="1.5" />
                      </g>
                    </svg>
                  </div>
                  <button type="button" className="btn hide_show icon_password">
                    <i className="tio-hidden_outlined" />
                  </button>
                </div>
                <label htmlFor="pass">Password</label>
              </div>
              <div className="alert alert-light text-center" role="alert">
                {
                  validation !== "" ? (<div>{validation}</div>) : ''
                }
              </div>
              <div className="buttons__footer text-center bg-transparent">
                <button type="submit" className="btn bg-primary rounded-pill btn__default">
                  <span className="color-white">Sign in</span>
                  <div className="icon rounded-circle">
                    <i className="tio-chevron_right" />
                  </div>
                </button>
                <a href="/register" className="btn border-snow rounded-pill btn__default">
                  <span className="color-secondary size-14">
                    I don't have an account
                  </span>
                </a>
              </div>
            </form>
          </div>
        </section>
        {/* End. em__signTypeOne */}
      </div>
    </div>
  )
}

export default AuthLogin;